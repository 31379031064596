import { QuestionnaireAnswer, QuestionnaireElement } from "@remhealth/apollo";
import { Markup } from "@remhealth/core";
import { QuestionnaireOutput } from "~/questionnaire/narrative/questionnaireOutput";
import { Text } from "~/text";
import { SignatureCard } from "./signatureCard";
import { Container, SectionHeader } from "./common.styles";

export interface QuestionnairePreviewOutputProps {
  answers: QuestionnaireAnswer[];
  elements: QuestionnaireElement[];
  sectionName: string;
  allowTables?: boolean;
  isPatientView?: boolean;
  includeSectionHeader: boolean;
}

export const QuestionnairePreviewOutput = (props: QuestionnairePreviewOutputProps) => {
  const { sectionName, elements, answers, allowTables, isPatientView, includeSectionHeader } = props;

  return (
    <Container>
      {includeSectionHeader && <SectionHeader>{sectionName}</SectionHeader>}
      <QuestionnaireOutput
        allowTables={allowTables}
        answers={answers}
        elements={elements}
        isPatientView={isPatientView}
        noDataMessage={!isPatientView && includeSectionHeader ? Text.NoData : undefined}
      />
      {renderPractitionerSignatures()}
    </Container>
  );

  function renderPractitionerSignatures() {
    const signatureElements = elements.flatMap(e => e.presentationHint === "PractitionerSignature" ? e : []) ?? [];
    return signatureElements.map(renderPractitionerSignature);
  }

  function renderPractitionerSignature(element: QuestionnaireElement) {
    const valueSignature = answers.find(a => a.linkId === element.linkId)?.values.at(0)?.valueSignature;
    if (valueSignature) {
      return (
        <div key={element.linkId}>
          <Markup source={element.text?.value} />
          <SignatureCard signature={valueSignature} />
        </div>
      );
    }
    return null;
  }
};
