import React from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import type { DataAttributes } from "~/types";
import { FormField, getIntent } from "../utils/form";
import { useAutomation, useCallbackRef, useUpdateEffect } from "../hooks";
import { getComponentId } from "./formScope";

export interface CheckboxProps extends Core.CheckboxProps, Core.IntentProps, DataAttributes {
  /**
   * Field to map against a string value.
   * The checkbox will be checked if the field's value matches the checkbox's value.
   * This takes precedence over the `field` prop.
   */
  groupField?: FormField<(string | number | undefined)[]>;

  /**
   * Field to map against a boolean value.
   * The checkbox will be checked if the field's value is true.
   * This is ignored if `groupField` is used.
   */
  field?: FormField<boolean | undefined>;
}

export function Checkbox(props: CheckboxProps) {
  const {
    className,
    field,
    groupField,
    intent = getIntent(field),
    name = groupField ? groupField.name : field?.name,
    label,
    id: controlledId,
    readOnly = groupField?.readOnly || field?.readOnly,
    disabled = groupField?.disabled || field?.disabled,
    checked: controlledChecked,
    value,
    onBlur,
    onChange,
    ...checkboxProps
  } = props;

  const checked = checkboxChecked(props);

  const { label: controlLabel, id, errorId } = useAutomation({ ...props, id: controlledId, field: groupField ?? field, name });
  const optionId = getComponentId(id, `option[${value ?? ""}]`);

  const handleFieldChange = useCallbackRef((checked: boolean) => {
    field?.onChange(checked);
    field?.onTouched();

    if (groupField && value !== undefined) {
      const included = includesValue(groupField.value, value);

      if (checked && !included) {
        groupField.onChange(groupField.value.concat(value));
        groupField.onTouched();
      } else if (!checked && included) {
        groupField.onChange(groupField.value.filter(v => !valueEquals(v, value)));
        groupField.onTouched();
      }
    }
  });

  // Announce new value if prop changed
  useUpdateEffect(() => {
    if (controlledChecked !== undefined) {
      handleFieldChange(controlledChecked);
    }
  }, [controlledChecked]);

  return renderControl({
    ...checkboxProps,
    "type": "checkbox",
    "typeClassName": Core.Classes.CHECKBOX,
    "aria-invalid": field?.error ? true : undefined,
    "aria-errormessage": errorId,
    "aria-label": getControlLabel(controlLabel, label ?? value),
    "className": classnames(Core.Classes.intentClass(intent), className),
    "onBlur": handleBlur,
    "onChange": handleChange,
    "id": props.id ?? optionId,
    checked,
    disabled,
    label,
    name,
    readOnly,
    value,
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event);

    if (!field?.readOnly && !field?.disabled && !groupField?.readOnly && !groupField?.disabled) {
      handleFieldChange(event.currentTarget.checked);
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur?.(event);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
      // Do not report touched for groupField, as this checkbox is only one amongst many
    }
  }
}

Checkbox.displayName = "Checkbox";

export function checkboxChecked(props: CheckboxProps): boolean | undefined {
  const { checked, groupField, field, value } = props;

  return checked !== undefined
    ? checked
    : groupField
      ? groupField.value.some(v => valueEquals(v, value))
      : field ? field.value === true : undefined;
}

export interface RadioProps extends Core.RadioProps, Core.IntentProps, DataAttributes {
  /**
   * Field to map against a string value.
   * The radio will be checked if the field's value matches the checkbox's value.
   * This takes precedence over the `field` prop.
   */
  groupField?: FormField<(string | number | ReadonlyArray<string> | undefined)>;

  /**
   * Field to map against a boolean value.
   * The radio will be checked if the field's value is true.
   * This is ignored if `groupField` is used.
   */
  field?: FormField<boolean | undefined>;
}

export function Radio(props: RadioProps) {
  const {
    className,
    field,
    groupField,
    intent = getIntent(field),
    label,
    id: controlledId,
    name = groupField ? groupField.name : field?.name,
    readOnly = groupField?.readOnly || field?.readOnly,
    disabled = groupField?.disabled || field?.disabled,
    checked: controlledChecked,
    value,
    onBlur,
    onChange,
    ...radioProps
  } = props;

  const checked = radioChecked(props);

  const { label: controlLabel, id, errorId } = useAutomation({ ...props, id: controlledId, field: groupField ?? field, name });
  const optionId = getComponentId(id, `option[${value ?? ""}]`);

  const handleFieldChange = useCallbackRef((checked: boolean) => {
    field?.onChange(checked);
    field?.onTouched();

    if (groupField && value !== undefined && checked) {
      groupField.onChange(value);
      groupField.onTouched();
    }
  });

  // Announce new value if prop changed
  useUpdateEffect(() => {
    if (controlledChecked !== undefined) {
      handleFieldChange(controlledChecked);
    }
  }, [controlledChecked]);

  return renderControl({
    ...radioProps,
    "type": "radio",
    "typeClassName": Core.Classes.RADIO,
    "aria-errormessage": errorId,
    "aria-invalid": field?.error ? true : undefined,
    "aria-label": getControlLabel(controlLabel, label ?? value),
    "className": classnames(Core.Classes.intentClass(intent), className),
    "onBlur": handleBlur,
    "onChange": handleChange,
    "id": props.id ?? optionId,
    // Purposefully exclude name, to prevent odd grouping behavior with similarly named radio button groups rendered elsewhere
    "name": undefined,
    checked,
    disabled,
    label,
    readOnly,
    value,
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event);

    if (!field?.readOnly && !field?.disabled && !groupField?.readOnly && !groupField?.disabled) {
      handleFieldChange(event.currentTarget.checked);
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur?.(event);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
      // Do not report touched for groupField, as this radio is only one amongst many
    }
  }
}

Radio.displayName = "Radio";

export function radioChecked(props: RadioProps): boolean | undefined {
  const { checked, groupField, field, value } = props;

  return checked !== undefined
    ? checked
    : groupField
      ? valueEquals(groupField.value, value)
      : field ? field.value : undefined;
}

export interface SwitchProps extends Core.SwitchProps, Core.IntentProps {
  /**
   * Field to map against a string value.
   * The switch will be checked if the field's value matches the checkbox's value.
   * This takes precedence over the `field` prop.
   */
  groupField?: FormField<(string | number | undefined)[]>;

  /**
   * Field to map against a boolean value.
   * The switch will be checked if the field's value is true.
   * This is ignored if `groupField` is used.
   */
  field?: FormField<boolean | undefined>;
}

export const Switch = (props: SwitchProps) => {
  const {
    className,
    field,
    groupField,
    id: controlledId,
    intent = getIntent(field),
    name = groupField ? groupField.name : field?.name,
    readOnly = groupField?.readOnly || field?.readOnly,
    disabled = groupField?.disabled || field?.disabled,
    checked: controlledChecked,
    innerLabel,
    innerLabelChecked,
    value,
    onBlur,
    onChange,
    ...switchProps
  } = props;

  const checked = switchChecked(props);

  const handleFieldChange = useCallbackRef((checked: boolean) => {
    field?.onChange(checked);
    field?.onTouched();

    if (groupField && value !== undefined) {
      const included = includesValue(groupField.value, value);

      if (checked && !included) {
        groupField.onChange(groupField.value.concat(value));
        groupField.onTouched();
      } else if (!checked && included) {
        groupField.onChange(groupField.value.filter(v => !valueEquals(v, value)));
        groupField.onTouched();
      }
    }
  });

  const { label: ariaLabel, id, errorId } = useAutomation({ ...props, id: controlledId, field: groupField ?? field, name });

  // Announce new value if prop changed
  useUpdateEffect(() => {
    if (controlledChecked !== undefined) {
      handleFieldChange(controlledChecked);
    }
  }, [controlledChecked]);

  const switchLabels = innerLabel || innerLabelChecked
    ? [
      <div key="checked" className="inner-label checked"><span>{innerLabelChecked ? innerLabelChecked : innerLabel}</span></div>,
      <div key="unchecked" className="inner-label"><span>{innerLabel}</span></div>,
    ]
    : null;

  return renderControl({
    ...switchProps,
    "indicatorChildren": switchLabels,
    "type": "checkbox",
    "typeClassName": Core.Classes.SWITCH,
    "aria-errormessage": errorId,
    "aria-invalid": field?.error ? true : undefined,
    "aria-label": ariaLabel,
    "className": classnames(Core.Classes.intentClass(intent), className),
    "onBlur": handleBlur,
    "onChange": handleChange,
    checked,
    disabled,
    id,
    name,
    readOnly,
    value,
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event);

    if (!field?.readOnly && !field?.disabled && !groupField?.readOnly && !groupField?.disabled) {
      handleFieldChange(event.currentTarget.checked);
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur?.(event);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
      // Do not report touched for groupField, as this checkbox is only one amongst many
    }
  }
};

export function switchChecked(props: SwitchProps): boolean | undefined {
  const { checked, groupField, field, value } = props;

  return checked !== undefined
    ? checked
    : groupField
      ? groupField.value.some(v => valueEquals(v, value))
      : field ? field.value : undefined;
}

interface ControlInternalProps extends Core.ControlProps {
  type: "checkbox" | "radio";
  typeClassName: string;
  indicatorChildren?: React.ReactNode;
}

function renderControl(props: ControlInternalProps) {
  const {
    alignIndicator,
    children,
    className,
    id,
    indicatorChildren,
    inline,
    inputRef,
    label,
    labelElement,
    large,
    readOnly,
    style,
    type,
    typeClassName,
    tagName = "label",
    ...htmlProps
  } = props;

  const labeled = !!label || !!labelElement || !!children;
  const wrapperId = getComponentId(id, "wrapper");

  const classes = classnames(
    Core.Classes.CONTROL,
    typeClassName,
    {
      [Core.Classes.DISABLED]: htmlProps.disabled,
      [Core.Classes.INLINE]: inline,
      [Core.Classes.LARGE]: large,
      readOnly,
      labeled,
    },
    Core.Classes.alignmentClass(alignIndicator),
    className
  );

  return React.createElement(
    tagName,
    { className: classes, style, id: wrapperId },
    <div className="indicator">
      <input {...htmlProps} ref={inputRef} id={id} type={type} />
      {indicatorChildren}
    </div>,
    label,
    labelElement,
    children
  );
}

function includesValue(values: (string | number | undefined)[], value: string | ReadonlyArray<string> | number | undefined): boolean {
  if (value === undefined) {
    return false;
  }

  if (isArray(value)) {
    return value.some(v => values.includes(v));
  }

  if (typeof value === "number") {
    return values.includes(value.toString());
  }

  return values.includes(value);
}

function valueEquals(left: string | number | ReadonlyArray<string> | undefined, right: string | ReadonlyArray<string> | number | undefined): boolean {
  if (left === undefined) {
    return false;
  }

  if (right === undefined) {
    return false;
  }

  if (Array.isArray(left)) {
    if (!Array.isArray(right)) {
      return false;
    }
    return left.every((i, index) => right[index] === i);
  }

  const leftStr = left.toString();

  if (Array.isArray(right)) {
    return right.some(v => valueEquals(left, v));
  }

  return leftStr === right.toString();
}

function isArray(array: any | ReadonlyArray<any>): array is ReadonlyArray<string> {
  return Array.isArray(array);
}

function getControlLabel(controlLabel: string | undefined, optionLabel: string | ReadonlyArray<string> | number | undefined) {
  if (!controlLabel) {
    return undefined;
  }

  if (optionLabel) {
    return `${controlLabel} - ${optionLabel}`;
  }

  return controlLabel;
}
