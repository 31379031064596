import { find } from "lodash-es";
import { BirthSex, ContactMethod, ContactPointUse, LocalDate, LocationRole, NameUse, knownCodings } from "@remhealth/apollo";
import { DateFormats } from "@remhealth/ui";
import { getPatientGender } from "~/components";
import { formatPhone } from "~/utils/misc";
import { PlaceholderContext } from "./contexts";

export interface Placeholder {
  name: string;
  display: string;
  autoReplace: boolean;
  resolver: (context: PlaceholderContext) => string | undefined;
}

type PronounForm = "nominative" | "accusative" | "genitive" | "reflexive";
type Gender = "masculine" | "feminine" | "neutral";

type Pronoun = { [gender in Gender]: { [pronounForm in PronounForm]: string} };

const pronouns: Pronoun = {
  masculine: {
    nominative: "he",
    accusative: "him",
    genitive: "his",
    reflexive: "himself",
  },
  feminine: {
    nominative: "she",
    accusative: "her",
    genitive: "her",
    reflexive: "herself",
  },
  neutral: {
    nominative: "they",
    accusative: "them",
    genitive: "their",
    reflexive: "themselves",
  },
};

export const placeholders: Placeholder[] = [
  {
    name: "firstName",
    display: "First name",
    autoReplace: true,
    resolver: resolveFirstName,
  },
  {
    name: "aka",
    display: "Also Known As",
    autoReplace: true,
    resolver: resolveAka,
  },
  {
    name: "age",
    display: "age",
    autoReplace: true,
    resolver: resolveAge,
  },
  {
    name: "heShe",
    display: "he/she",
    autoReplace: true,
    resolver: (context) => resolvePronoun(context, "nominative"),
  },
  {
    name: "HeShe",
    display: "He/She",
    autoReplace: true,
    resolver: (context) => resolvePronoun(context, "nominative", true),
  },
  {
    name: "hisHer",
    display: "his/her",
    autoReplace: true,
    resolver: (context) => resolvePronoun(context, "genitive"),
  },
  {
    name: "HisHer",
    display: "His/Her",
    autoReplace: true,
    resolver: (context) => resolvePronoun(context, "genitive", true),
  },
  {
    name: "himHer",
    display: "him/her",
    autoReplace: true,
    resolver: (context) => resolvePronoun(context, "accusative"),
  },
  {
    name: "HimHer",
    display: "Him/Her",
    autoReplace: true,
    resolver: (context) => resolvePronoun(context, "accusative", true),
  },
  {
    name: "guardian",
    display: "Guardian",
    autoReplace: true,
    resolver: resolveGuardianName,
  },
  {
    name: "singleChoice",
    display: "Select from a list",
    autoReplace: false,
    resolver: () => undefined,
  },
  {
    name: "multiChoice",
    display: "Select from a list",
    autoReplace: false,
    resolver: () => undefined,
  },
  {
    name: "patientNoun",
    display: "Patient noun",
    autoReplace: true,
    resolver: (context) => context.practicePreferences?.patientLabel.singular,
  },
  {
    name: "user",
    display: "User",
    autoReplace: true,
    resolver: resolveUserName,
  },
  {
    name: "userRole",
    display: "User role",
    autoReplace: true,
    resolver: resolveUserRole,
  },
  {
    name: "patientState",
    display: "Patient state",
    autoReplace: true,
    resolver: resolvePatientState,
  },
  {
    name: "patientCity",
    display: "Patient city",
    autoReplace: true,
    resolver: resolvePatientCity,
  },
  {
    name: "patientStreet",
    display: "Patient street",
    autoReplace: true,
    resolver: resolvePatientStreet,
  },
  {
    name: "patientPostalCode",
    display: "Patient postal code",
    autoReplace: true,
    resolver: resolvePatientPostalCode,
  },
  {
    name: "patientGender",
    display: "Patient gender",
    autoReplace: true,
    resolver: resolvePatientGender,
  },
  {
    name: "patientHomePhone",
    display: "Patient home phone",
    autoReplace: true,
    resolver: resolveHomePhone,
  },
  {
    name: "patientWorkPhone",
    display: "Patient work phone",
    autoReplace: true,
    resolver: resolveWorkPhone,
  },
  {
    name: "patientMobilePhone",
    display: "Patient mobile phone",
    autoReplace: true,
    resolver: resolveMobilePhone,
  },
  {
    name: "patientEmail",
    display: "Patient email",
    autoReplace: true,
    resolver: resolvePatientEmail,
  },
  {
    name: "location",
    display: "Location",
    autoReplace: true,
    resolver: resolveLocation,
  },
  {
    name: "placeOfService",
    display: "Place of service",
    autoReplace: true,
    resolver: resolvePlaceOfService,
  },
];

function resolveLocation(context: PlaceholderContext) {
  return context.location?.display;
}

function resolvePlaceOfService(context: PlaceholderContext) {
  const { placeOfService } = context;

  if (typeof placeOfService === "string" && placeOfService in LocationRole) {
    return getPlaceOfService(placeOfService)?.display;
  } else if (typeof placeOfService === "object" && placeOfService.display) {
    return placeOfService.display;
  }
  return undefined;
}

function resolvePatientStreet(context: PlaceholderContext) {
  return context.patient?.addresses[0]?.lines[0];
}

function resolvePatientEmail(context: PlaceholderContext) {
  return context.patient?.telecoms.find(t => t.system === ContactMethod.Email)?.value;
}

function resolveHomePhone(context: PlaceholderContext) {
  return resolvePhoneNumber(context, ContactPointUse.Home);
}

function resolveWorkPhone(context: PlaceholderContext) {
  return resolvePhoneNumber(context, ContactPointUse.Work);
}

function resolveMobilePhone(context: PlaceholderContext) {
  return resolvePhoneNumber(context, ContactPointUse.Mobile);
}

function resolvePhoneNumber(context: PlaceholderContext, use: ContactPointUse) {
  const phone = context.patient?.telecoms.find(t => t.system === ContactMethod.Phone && t.use === use)?.value;
  if (phone) {
    return formatPhone(phone);
  }
  return undefined;
}

function resolvePatientGender(context: PlaceholderContext) {
  if (context.patient) {
    const gender = getPatientGender(context.patient);
    return gender.display.toLowerCase();
  }
  return undefined;
}

function resolvePatientPostalCode(context: PlaceholderContext) {
  return context.patient?.addresses[0]?.postalCode;
}

function resolvePatientState(context: PlaceholderContext) {
  return context.patient?.addresses[0]?.state;
}

function resolvePatientCity(context: PlaceholderContext) {
  return context.patient?.addresses[0]?.city;
}

function resolveFirstName(context: PlaceholderContext) {
  return context.patient?.name.given[0];
}

function resolveUserName(context: PlaceholderContext) {
  return context.practitioner?.otherNames.find(u => u.use === NameUse.Preferred)?.display ?? context.practitioner?.display;
}

function resolveUserRole(context: PlaceholderContext) {
  return context.practitioner?.designation?.text ?? "User Role";
}

function resolveAka(context: PlaceholderContext) {
  const targetName = context.patient?.otherNames?.find(n => n.use === NameUse.Nickname);
  return targetName?.display ?? resolveFirstName(context);
}

function resolveAge(context: PlaceholderContext) {
  return context.patient ? DateFormats.age(LocalDate.toDate(context.patient.birthDate), "long") : undefined;
}

function resolvePronoun(context: PlaceholderContext, pronounForm: PronounForm, captilized?: boolean) {
  const { patient } = context;

  if (!patient) {
    return undefined;
  }

  let gender: Gender;

  const genderCoding = getPatientGender(patient);
  if (genderCoding.code === "M" || genderCoding.code === "TM") {
    gender = "masculine";
  } else if (genderCoding.code === "F" || genderCoding.code === "TF") {
    gender = "feminine";
  } else if (patient.birthSex === BirthSex.Male) {
    gender = "masculine";
  } else if (patient.birthSex === BirthSex.Female) {
    gender = "feminine";
  } else {
    gender = "neutral";
  }

  const appliedPronoun = pronouns[gender][pronounForm];
  if (captilized) {
    return appliedPronoun.charAt(0).toUpperCase() + appliedPronoun.slice(1);
  }

  return appliedPronoun;
}

function resolveGuardianName(context: PlaceholderContext) {
  const { guardian } = context;
  if (guardian) {
    return guardian.display;
  }
  return undefined;
}

function getPlaceOfService(locationRole: LocationRole) {
  return find(knownCodings.placeOfServices, (_, key) => key.toLowerCase() === locationRole.toLowerCase());
}
