import { Practice, Product } from "@remhealth/apollo";
import { useUserSession } from "./auth";

const productFlags = {
  AdditionalSessionTime: [Product.ClaimTrak],
  AdminNoteDelete: [Product.ClaimTrak, Product.myAvatar],
  AllowAddOnSection: [Product.myAvatar],
  AllowAutomaticallyCreateNoteCancelledAppointment: [], // Will be supported in CT|One in the future
  AllowCancelAppt: [Product.myAvatar, Product.myEvolv],
  AllowCancelVisitStatus: [Product.ClaimTrak, Product.myAvatar],
  AllowCaptureSignature: [Product.ClaimTrak],
  AllowConfigureSupervisors: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  AllowCtOneCustomDropDown: [Product.ClaimTrak],
  AllowEBPSection: [Product.myAvatar],
  AllowEhrCns: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  AllowFilterDxByProgram: [Product.ClaimTrak],
  AllowGoalsInNoteNarrative: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  AllowGroupTopicManagement: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  AllowMarkAllReview: [Product.ClaimTrak],
  AllowPatientExclusionManagement: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  AllowProblemLabelSubstitution: [Product.myAvatar, Product.myEvolv],
  AllowSendAsDraft: [Product.myAvatar],
  AllowServiceTypeOverride: [Product.ClaimTrak, Product.myAvatar],
  AppointmentInvitedAttendeesOnly: [Product.myAvatar],
  AssessmentNotes: [Product.myAvatar],
  Authorizations: [Product.myAvatar],
  CanStartNoteFromCancelledAppt: [Product.ClaimTrak],
  CaptureWorkEmail: [Product.ClaimTrak],
  ClinicalQualityIndicator: [Product.myAvatar],
  CNSDuration: [Product.myEvolv],
  CNSImports: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  CNSStatusFilter: [Product.ClaimTrak, Product.myAvatar],
  ConfigureLocation: [Product.ClaimTrak],
  CoPractitionerField: [Product.myAvatar],
  CreateApptForNote: [Product.myAvatar],
  DefaultNonEpisodicGoals: [Product.myEvolv, Product.GEHRIMED],
  LocationsLiveCall: [Product.myAvatar, Product.myEvolv],
  EnableUnsignWithinThreshold: [Product.ClaimTrak],
  EnrollmentOptional: [Product.GEHRIMED],
  EpisodeAdmissionLabeling: [Product.myAvatar],
  FilterDxByEpisode: [Product.ClaimTrak, Product.myAvatar],
  GoalTracking: [Product.ClaimTrak],
  GoalsMinimumLevel: [Product.myAvatar, Product.myEvolv],
  GroupRegistration: [Product.myEvolv],
  GroupNoteEnrollmentField: [Product.ClaimTrak, Product.myAvatar],
  GroupNoteServiceRestrict: [Product.ClaimTrak, Product.myAvatar, Product.myEvolv],
  GroupNotesAdhocPatients: [Product.ClaimTrak, Product.myAvatar],
  GroupNotesMultiNoteType: [Product.ClaimTrak, Product.myAvatar],
  GroupNotePreferredProgram: [Product.ClaimTrak, Product.myAvatar],
  GroupNotePutAppointment: [Product.myAvatar],
  GroupNoteTypeSameNoteForm: [Product.myEvolv],
  GroupsHaveID: [Product.ClaimTrak, Product.myAvatar],
  GroupsHaveEffectiveRange: [Product.ClaimTrak, Product.myEvolv],
  GroupsHaveStaff: [Product.ClaimTrak, Product.myEvolv],
  HasDiagnosisPeriod: [Product.ClaimTrak, Product.myEvolv],
  HasDiagnosisSection: [Product.ClaimTrak, Product.myEvolv],
  HasDiagnosisStatus: [Product.myAvatar],
  HasStaffId: [Product.ClaimTrak, Product.myAvatar],
  HasWorkgroups: [Product.myEvolv],
  HasAdmissionDiagnosis: [Product.myAvatar],
  HasPlaceOfService: [Product.ClaimTrak, Product.myEvolv],
  IndependentNoteType: [Product.myAvatar],
  InsuranceFilter: [Product.ClaimTrak, Product.myAvatar],
  MapNoteNarrative: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  MissedVisit: [Product.myAvatar],
  NoteTypeProgramLimit: [Product.ClaimTrak, Product.myAvatar, Product.myEvolv],
  NumberedGoals: [Product.ClaimTrak],
  PlaceOfServiceFilterByProgram: [Product.myEvolv],
  PlaceOfServicePreference: [Product.myEvolv],
  PreferProgramEnrollments: [Product.myEvolv],
  ProblemListProblems: [Product.myAvatar, Product.myEvolv],
  ProblemListSection: [Product.myAvatar, Product.myEvolv],
  ProgramSidebar: [Product.myEvolv],
  ProgramSelectionValidation: [Product.ClaimTrak, Product.myAvatar],
  RenderImportCNSInline: [Product.myAvatar],
  RequireApptEpisodeToStartNote: [Product.myAvatar, Product.myEvolv],
  RequireCNSTextSection: [Product.myAvatar],
  RequireGroupProgram: [Product.myEvolv],
  RequireLocationKind: [Product.myEvolv],
  RequireNarrativeForAnswerableCNS: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  NoteForms: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  NoteTypeRequiresNarrative: [Product.myAvatar],
  NoteDialogNotesTab: [Product.GEHRIMED],
  QualificationTitle: [Product.myAvatar],
  ScopeLocationToRoles: [Product.ClaimTrak],
  SearchPatientByLastName: [Product.myAvatar],
  SearchUserByLastName: [Product.myAvatar, Product.myEvolv, Product.GEHRIMED],
  ServiceLocationKind: [Product.myAvatar], // MyAvatar uses Kind instead of Instance for their service location
  ServiceTypeByPatientCall: [Product.myAvatar],
  ServiceTypeLiveCall: [Product.myAvatar, Product.myEvolv],
  ShowAppointmentVisitStatusMapping: [Product.myAvatar],
  ShowBillableAlert: [Product.ClaimTrak],
  ShowBillableNonBillableConflictAlert: [Product.ClaimTrak],
  ShowCancelledStatus: [Product.ClaimTrak, Product.myAvatar],
  ShowConfidentialMenu: [Product.ClaimTrak, Product.myAvatar],
  ShowDefaultStartNoteAction: [Product.myAvatar],
  ShowEnrollmentInNoteGrid: [Product.ClaimTrak, Product.myAvatar, Product.GEHRIMED],
  ShowLocationRole: [Product.ClaimTrak],
  ShowLocationsInSettings: [Product.ClaimTrak],
  ShowNonBillableandUnits: [Product.ClaimTrak],
  ShowNonEpisodicGoalFilterOption: [Product.myAvatar, Product.myEvolv],
  ShowNoteCategoryField: [Product.myAvatar],
  ShowNoteFormCode: [Product.myAvatar],
  ShowProgramField: [Product.myAvatar],
  ShowUserAccessStatus: [Product.ClaimTrak],
  ShowStaffHireDate: [Product.ClaimTrak, Product.myAvatar],
  ShowStaffUsername: [Product.myAvatar],
  SignatureConfigurations: [Product.ClaimTrak],
  Subforms: [Product.myEvolv],
  TreatmentPlans: [Product.ClaimTrak, Product.myAvatar, Product.myEvolv],
  WarnSpanMidnightOutcome: [Product.myEvolv],
} as const satisfies Record<string, ReadonlyArray<Product>>;

export type ProductFlags = keyof typeof productFlags;

export function useProductFlag(flag: ProductFlags): boolean {
  const userSession = useUserSession();
  return getProductFlag(userSession.practice, flag);
}

export function getProductFlag(practice: Practice, flag: ProductFlags): boolean {
  if (!practice.product) {
    return false;
  }

  const allowedProducts = productFlags[flag] as ReadonlyArray<Product>;
  return allowedProducts.includes(practice.product);
}
