import { type ReactNode, type SetStateAction, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import type { ValidateOptions } from "yup";
import isEqual from "react-fast-compare";
import { isPlainObject } from "lodash-es";
import { type FormFields, type FormFieldsActions, type FormFieldsState, createFormFields, useCallbackRef } from "@remhealth/ui";
import { ValidationError, prepareForValidation } from "~/validation";
import { getIn, isValidationError, setIn, setNestedObjectValues, yupToFormErrors } from "./utils";
import type { FormActions, FormErrors, FormState, FormTouched, FormValues } from "./types";
import { FormContext } from "./context";

interface ValidationSchema<T> {
  validateSync(value: any, options?: ValidateOptions): T;
}

type ValidationSchemaFactory<T> = ValidationSchema<T> | (() => ValidationSchema<T>);

export type ResetEventHandler = () => void;
export type Unsubscriber = () => void;

export interface FormContent<T extends FormValues> extends FormActions<T>, FormState<T> {
  readonly key: number;
  readonly iteration: number;
    /** If true, all fields linked to this form will be readonly. */
  readonly readOnly: boolean;
    /** If true, all fields linked to this form will be disabled. */
  readonly disabled: boolean;
  readonly fields: FormFields<T>;
  onReset(handler: ResetEventHandler): Unsubscriber;
}

export interface FormProps<T extends FormValues> extends FormInstanceProps<T> {
  /** If true, all fields linked to this form will be readonly. */
  readOnly?: boolean;
  /** If true, all fields linked to this form will be disabled. */
  disabled?: boolean;
  /**
   * If true, all fields linked to this form will be disabled during submit.
   * @default true
   */
  disableIfSubmitting?: boolean;
  onReset?: (values: T, formActions: FormActions<T>) => void;
  onChange?: (values: T) => void;
  onSubmit?: (values: T, formActions: FormActions<T>) => void | Promise<void>;
  children?: (content: FormContent<T>) => ReactNode;
}

export type Form<T extends FormValues> = FormActions<T>;

function FormComponent<T extends FormValues>(props: FormProps<T>, ref: React.Ref<Form<T>>) {
  // eslint-disable-next-line react/hook-use-state
  const [iteration, setIteration] = useState(0);

  const onValuesChange = useCallbackRef(props.onChange);

  const form = useMemo(() => new FormInstance<T>(props, dispatch, onValuesChange), []);
  const resetHandlers = useMemo(() => new Map<number, ResetEventHandler>(), []);

  form.update(props);

  useEffect(() => {
    if (iteration !== form.iteration) {
      setIteration(form.iteration);
    }
  }, [form.iteration]);

  const dirty = useMemo(() => !isEqual(form.initialValues, form.values), [form.initialValues, form.values]);

  // Use form key to track isSubmitting, in case form gets reset during submission
  const [isSubmittingFormKey, setIsSubmittingFormKey] = useState<number>();
  const [submitCount, setSubmitCount] = useState(0);

  const {
    children,
    onReset,
    onSubmit,
    readOnly = false,
    disabled: controlledDisabled = false,
    disableIfSubmitting = true,
  } = props;

  const onResetCallback = useCallbackRef(onReset);
  const onSubmitCallback = useCallbackRef(onSubmit, true);

  const isSubmitting = isSubmittingFormKey === form.key;
  const forceDisabled = disableIfSubmitting && isSubmitting;
  const disabled = controlledDisabled || forceDisabled;

  const fieldActions = useMemo<FormFieldsActions>(() => ({
    setFieldValue: (...args) => form.setFieldValue(...args),
    setFieldError: (...args) => form.setFieldError(...args),
    setFieldTouched: (...args) => form.setFieldTouched(...args),
  }), [form]);

  const actions = useMemo<FormActions<T>>(() => ({
    ...fieldActions,
    setValues: (...args) => form.setValues(...args),
    setAllTouched: (...args) => form.setAllTouched(...args),
    validateForm: (...args) => form.validate(...args),
    setSubmitting: (isSubmitting) => setIsSubmittingFormKey(isSubmitting ? form.key : undefined),
    resetForm,
    submitForm,
  }), [form, fieldActions]);

  const fieldsState: FormFieldsState<T> = {
    get initialValues(): T {
      return form.initialValues;
    },
    get values(): T {
      return form.values;
    },
    get errors(): FormErrors<T> {
      return form.errors;
    },
    get touched(): FormTouched<T> {
      return form.touched;
    },
  };

  const state: FormState<T> = {
    ...fieldsState,
    dirty,
    isSubmitting,
    submitCount,
    get isValid(): boolean {
      return Object.keys(form.errors).length === 0;
    },
  };

  const fields = createFormFields({
    readOnly,
    disabled,
    ...state,
    ...actions,
  }, []);

  const content: FormContent<T> = {
    key: form.key,
    iteration,
    readOnly,
    disabled,
    fields,
    ...state,
    ...actions,
    onReset: addResetEventHandler,
  };

  useImperativeHandle(ref, () => actions, [actions]);

  return (
    <FormContext.Provider value={{ form: content }}>
      {children?.(content)}
    </FormContext.Provider>
  );

  function dispatch() {
    setIteration(form.iteration);
  }

  function resetForm(nextValues?: T): void {
    onResetCallback(form.values, actions);

    form.reset(nextValues);

    resetHandlers.forEach(handler => handler());
  }

  async function submitForm(): Promise<void> {
    setSubmitCount(c => c + 1);

    form.setAllTouched();

    const errors = form.validationNeeded ? form.validate() : form.errors;

    if (Object.keys(errors).length !== 0) {
      throw new ValidationError(Object.keys(errors), errors, "");
    }

    if (!onSubmitCallback) {
      return;
    }

    try {
      setIsSubmittingFormKey(form.key);
      await onSubmitCallback(form.values, actions);
    } finally {
      setIsSubmittingFormKey(undefined);
    }
  }

  function addResetEventHandler(handler: ResetEventHandler): Unsubscriber {
    const subscription = Math.random();
    resetHandlers.set(subscription, handler);
    return () => resetHandlers.delete(subscription);
  }
}

export const Form = forwardRef(FormComponent) as <T extends FormValues>(props: FormProps<T> & { ref?: React.Ref<Form<T>>}) => JSX.Element;

export interface FormInstanceProps<T extends FormValues> {
  initialValues: T;

  /** @default true */
  validateOnChange?: boolean;

  /** @default true */
  validateOnBlur?: boolean;

  /**
   * Validate whenever the form initializes or initialValues changes
   * @default false
   */
  validateOnInitialize?: boolean;

  validationSchema?: ValidationSchemaFactory<Partial<T>>;
}

class FormInstance<T extends FormValues> {
  public values: T;
  public initialValues: T;
  public errors: FormErrors<T>;
  public touched: FormTouched<T>;
  public key: number;
  public iteration: number;
  public schema: ValidationSchemaFactory<Partial<T>> | undefined;
  public validateOnChange: boolean;
  public validateOnBlur: boolean;
  public validateOnInitialize: boolean;
  public validationNeeded: boolean;
  public validationRequested: boolean;
  public valuesReinitialized: boolean;
  private dispatch: () => void;
  private onValuesChange: (values: T) => void;
  private lastKnownInitialValues: T;
  private reinitializing = false;

  constructor(props: FormInstanceProps<T>, dispatch: () => void, onValuesChange: (values: T) => void) {
    const initialValues = prepareForValidation(props.initialValues);
    this.initialValues = this.lastKnownInitialValues = initialValues;
    this.values = deepClone(initialValues);
    this.errors = {};
    this.touched = {};
    this.key = Math.random();
    this.iteration = 0;
    this.schema = props.validationSchema;
    this.validateOnChange = props.validateOnChange ?? true;
    this.validateOnBlur = props.validateOnBlur ?? true;
    this.validateOnInitialize = props.validateOnInitialize ?? false;
    this.validationNeeded = true;
    this.validationRequested = this.validateOnInitialize;
    this.valuesReinitialized = false;
    this.dispatch = dispatch;
    this.onValuesChange = onValuesChange;
  }

  public update(props: FormInstanceProps<T>) {
    let {
      initialValues,
      validateOnChange = true,
      validateOnInitialize = false,
      validationSchema,
    } = props;

    this.reinitializing = true;
    this.validateOnChange = validateOnChange;
    this.validateOnInitialize = validateOnInitialize;

    let valuesChanged = false;
    let revalidateNeeded = false;

    // Use this.lastKnownInitialValues instead of this.initialValues
    // to allow resetForm() to be used with new desired starting point
    if (this.lastKnownInitialValues !== initialValues) {
      initialValues = prepareForValidation(initialValues);

      if (!isEqual(this.lastKnownInitialValues, initialValues)) {
        this.key = Math.random();
        this.initialValues = this.lastKnownInitialValues = initialValues;
        this.values = deepClone(initialValues);

        valuesChanged = true;

        // Reset form
        this.errors = {};
        this.touched = {};

        if (validateOnInitialize) {
          revalidateNeeded = true;
        }
      }
    }

    if (this.schema !== validationSchema) {
      this.schema = validationSchema;

      if (validateOnInitialize) {
        revalidateNeeded = true;
      }
    }

    if (revalidateNeeded) {
      this.validationRequested = true;
    }

    const revalidateNow = (revalidateNeeded && validateOnInitialize) || this.validationRequested;
    if (revalidateNow) {
      this.validate();
    }

    if (valuesChanged) {
      this.valuesReinitialized = true;
    }

    this.reinitializing = false;
  }

  public isDirty(): boolean {
    return !isEqual(this.initialValues, this.values);
  }

  public setFieldValue<TKey extends string & keyof T>(field: TKey, value: SetStateAction<T[TKey]>): void {
    if (typeof value === "function") {
      const setter = value as (prevState: T[TKey]) => T[TKey];
      this.setValuesInternal(values => {
        const prevValue: T[TKey] = getIn(values, field);
        const newValue = prepareForValidation(setter(prevValue));
        values = setIn(values, field, newValue);
        return values;
      }, false);
    } else {
      this.setValuesInternal(values => setIn(values, field, prepareForValidation(value)), false);
    }
  }

  public setValues(values: SetStateAction<T>) {
    this.setValuesInternal(values, true);
  }

  public setFieldError<TKey extends string & keyof T>(field: TKey, nextError: string | undefined): void {
    const prevError = getIn(this.errors, field);

    if (prevError !== nextError) {
      this.errors = setIn(this.errors, field, nextError);
      this.queueRender();
    }
  }

  public setFieldTouched<TKey extends string & keyof T>(field: TKey, nextIsTouched = true): void {
    const prevIsTouched = getIn(this.touched, field);

    if (prevIsTouched !== nextIsTouched) {
      this.touched = setIn(this.touched, field, nextIsTouched);
      this.queueRender();
    }

    const blurring = nextIsTouched && prevIsTouched !== nextIsTouched;

    if (this.validateOnBlur && blurring) {
      this.validationRequested = true;
    }
  }

  public setAllTouched(): void {
    this.touched = setNestedObjectValues<T>(deepClone(this.values), true);
    this.queueRender();
  }

  public reset(nextValues?: T) {
    if (nextValues !== undefined) {
      this.initialValues = prepareForValidation(nextValues);
    }

    this.setValuesInternal(this.initialValues, false);
    this.errors = {};
    this.touched = {};

    this.queueRender();
  }

  public validate(values?: T): FormErrors<T> {
    let errors: FormErrors<T> = {};

    if (this.schema) {
      const schema = typeof this.schema === "function" ? this.schema() : this.schema;
      const normalizedValues: T = prepareForValidation(values ?? this.values);

      try {
        schema.validateSync(normalizedValues, {
          abortEarly: false,
          context: normalizedValues,
        });
      } catch (error) {
        if (isValidationError(error)) {
          errors = yupToFormErrors<T>(error);
        }
      }
    }

    if (values === undefined) {
      this.errors = errors;
    } else {
      this.validationNeeded = false;
      this.validationRequested = false;
      this.queueRender();
    }

    return errors;
  }

  private setValuesInternal(values: SetStateAction<T>, performCleaning: boolean) {
    if (typeof values === "function") {
      this.values = values(this.values);
    } else {
      this.values = values;
    }

    if (performCleaning) {
      this.values = prepareForValidation(this.values);
    }

    this.onValuesChange(this.values);

    this.validationNeeded = true;
    this.queueRender();

    if (this.validateOnChange) {
      this.validationRequested = true;
    }
  }

  private queueRender() {
    this.iteration++;

    if (!this.reinitializing) {
      this.dispatch();
    }
  }
}

function deepClone<T extends FormValues>(values: T): T {
  const cloned = { ...values };

  for (const key in cloned) {
    if (Array.isArray(cloned[key])) {
      (cloned as any)[key] = cloned[key].map((item: any) => isPlainObject(item) ? deepClone(item) : item);
    } else if (isPlainObject(cloned[key])) {
      (cloned as any)[key] = deepClone(cloned[key]);
    }
  }

  return cloned;
}
