import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/select";
import type { DataAttributes } from "~/types";
import { DefaultNoResults, FormField, ItemListRenderer, ItemListRendererProps, ListGroupRenderer, extendItemListRendererProps, getIntent } from "../utils";
import { useAutomation, useControllableState, useUpdateEffect } from "../hooks";
import { InputGroupProps, toNativeProps as inputGroupToNativeProps } from "./inputGroup";
import { Menu, MenuProps } from "./menu";
import { usePopperModifiers } from "./popover";
import { FormScope, getComponentId } from "./formScope";
import { ListItemsProps, SelectPopoverProps, useListItems } from "./useListItems";
import { useSelectMenuProps } from "./useSelectMenuProps";
import { ClearButton } from "./common.styles";

type PropOmissions =
  | "items"
  | "activeItem"
  | "inputProps"
  | "inputValueRenderer"
  | "itemListRenderer"
  | "itemRenderer"
  | "menuProps"
  | "popoverProps"
  | "resetOnClose"
  | "resetOnQuery"
  | "resetOnSelect"
  | "scrollToActiveItem"
  | "onActiveItemChange"
  | "onItemSelect";

type LiftedInputProps = Pick<InputGroupProps,
  | "autoFocus"
  | "aria-label"
  | "disabled"
  | "intent"
  | "leftIcon"
  | "leftElement"
  | "rightElement"
  | "minimal"
  | "name"
  | "id"
  | "readOnly"
  | "placeholder"
  | "soft"
  | "large"
  | "clearable"
  | "onLeftIconClick"
>;

export interface SuggestProps<T> extends Omit<Core.SuggestProps<T>, PropOmissions | keyof ListItemsProps<T>>, ListItemsProps<T>, LiftedInputProps, DataAttributes {
  items: ReadonlyArray<T>;
  /**
   * Props for the Input rendered within the select input.
   */
  inputProps?: Omit<InputGroupProps, keyof LiftedInputProps | "field">;

  field?: FormField<T | undefined>;

  menuProps?: MenuProps;

  popoverProps?: SelectPopoverProps;

  itemListRenderer?: ItemListRenderer<T>;

  groupRenderer?: ListGroupRenderer<T, ItemListRendererProps<T>>;

  /**
   * Invoked when an item is selected.  Note that this does not get called if the selection is cleared.  Use `onSelectedItemChange` for selection changes.
   */
  onItemSelect?: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * Invoked when the selection changes or is cleared.
   */
  onSelectedItemChange?: (selectedItem: T | null) => void;
}

export interface Suggest {
  clearQuery(): void;
}

function SuggestComponent<T>(props: SuggestProps<T>, ref: React.Ref<Suggest>) {
  const {
    autoFocus,
    className,
    clearable = false,
    field,
    leftElement,
    leftIcon = leftElement ? undefined : "search",
    rightElement: controlledRightElement,
    name = field?.name,
    readOnly = field?.readOnly,
    items,
    placeholder,
    large = false,
    minimal = false,
    soft = false,
    inputProps = {},
    menuProps: controlledMenuProps,
    popoverProps = {},
    noResults = DefaultNoResults,
    disabled: controlledDisabled,
    initialContent,
    selectedItem: controlledSelectedItem,
    itemListRenderer = defaultItemListRenderer,
    groupRenderer,
    onLeftIconClick,
    onItemSelect,
    onSelectedItemChange,
    onQueryChange,
    ...restProps
  } = props;

  const { itemPredicate, itemRenderer, textRenderer } = useListItems(props, [groupRenderer]);

  const { width, maxWidth, maxHeight, matchTargetWidth, positioningStrategy = "fixed", ...restPopoverProps } = popoverProps;

  const [query, setQuery] = useControllableState<string>(props.query, onQueryChange, "");
  const [uncontrolledSelectedItem, setUncontrolledSelectedItem] = useState<T | null>(props.defaultSelectedItem ?? null);

  const selectedItem = controlledSelectedItem !== undefined ? controlledSelectedItem : field ? field.value ?? null : uncontrolledSelectedItem;
  const [activeItem, setActiveItem] = useState<T | Core.CreateNewItem | null>(selectedItem);

  let disabled = controlledDisabled;

  if (field?.disabled) {
    disabled = true;
  }

  const round = inputProps.round !== undefined ? inputProps.round : soft === true;

  const { label, id, controlId, errorId } = useAutomation(props);
  const clearButtonId = getComponentId(id, "clear");
  const inputId = getComponentId(id, "input");
  const popoverId = getComponentId(id, "popover");

  const [modifiers, modifiersCustom, popperPopoverTargetProps] = usePopperModifiers({
    label,
    popoverId,
    flowTo: inputId,
    positioningStrategy,
    modifiers: restPopoverProps.modifiers,
    modifiersCustom: restPopoverProps.modifiersCustom,
  });

  let rightElement = controlledRightElement;

  if (!rightElement && clearable) {
    rightElement = (
      <ClearButton
        minimal
        $hidden={!selectedItem}
        aria-label={label ? `Clear ${label}` : "Clear Suggest"}
        disabled={disabled}
        icon="cross"
        id={clearButtonId}
        square={!round}
        onClick={handleClear}
      />
    );
  }

  const nativeInputProps = inputGroupToNativeProps({
    ...inputProps,
    disabled,
    large,
    leftIcon,
    leftElement,
    rightElement,
    soft,
    minimal,
    onLeftIconClick,
  });

  const intent = getIntent(field) ?? props.intent;

  const sortedItems = useMemo(() => groupRenderer?.([...items]).flatMap(i => i.items) ?? [...items], [items, groupRenderer]);

  const menuProps = useSelectMenuProps({
    label: `${label ?? "Suggest List"} Items`,
    id,
    maxWidth,
    maxHeight,
    width,
    matchTargetWidth,
  });

  useUpdateEffect(() => {
    if (props.selectedItem) {
      field?.onChange(props.selectedItem);
      setUncontrolledSelectedItem(props.selectedItem ?? null);
    }
  }, [props.selectedItem]);

  useImperativeHandle(ref, () => ({
    clearQuery,
  }));

  // Fix issue with undefined acting like controlled value
  if (restPopoverProps && restPopoverProps.isOpen === undefined) {
    delete restPopoverProps.isOpen;
  }

  return (
    <FormScope controlId={controlId}>
      <Core.Suggest<T>
        {...restProps}
        scrollToActiveItem
        activeItem={activeItem}
        className={classnames("suggest", className)}
        disabled={disabled}
        inputProps={{
          ...popperPopoverTargetProps,
          ...nativeInputProps,
          "aria-controls": menuProps.id,
          "aria-invalid": field?.error ? true : undefined,
          "aria-errormessage": errorId,
          "data-errormessage": field?.error ? field.errorText : undefined,
          "defaultValue": nativeInputProps.defaultValue,
          "id": inputId,
          autoFocus,
          name,
          intent,
          readOnly,
          large,
          round,
          placeholder,
        } as Core.SuggestProps<T>["inputProps"]}
        inputValueRenderer={textRenderer}
        itemListRenderer={renderList}
        itemPredicate={itemPredicate}
        itemRenderer={itemRenderer}
        items={sortedItems}
        menuProps={menuProps}
        popoverProps={{
          targetTagName: "div",
          ...restPopoverProps,
          positioningStrategy,
          matchTargetWidth,
          modifiers,
          modifiersCustom,
          onOpening: handlePopoverOpening,
          onClosing: handlePopoverClosing,
          minimal: popoverProps?.minimal !== false,
        }}
        query={query}
        resetOnClose={false}
        resetOnQuery={false}
        resetOnSelect={false}
        selectedItem={selectedItem}
        onActiveItemChange={handleActiveItemChange}
        onItemSelect={handleItemSelect}
        onQueryChange={handleQueryChange}
      />
    </FormScope>
  );

  function handleActiveItemChange(activeItem: T | null, isCreateNewItem: boolean) {
    setActiveItem(isCreateNewItem ? Core.getCreateNewItem() : activeItem);
  }

  function handleItemSelect(item: T, event?: React.SyntheticEvent<HTMLElement>) {
    onItemSelect?.(item, event);
    handleSelectedItemChange(item);
  }

  function clearQuery() {
    handleQueryChange("");
  }

  function handleClear(): void {
    if (selectedItem) {
      handleSelectedItemChange(null);
    }

    // Clear query on clear button too
    clearQuery();
  }

  function handleSelectedItemChange(item: T | null): void {
    if (item === selectedItem) {
      return;
    }

    onSelectedItemChange?.(item);

    if (!field?.readOnly && !field?.disabled) {
      field?.onChange(item ?? undefined);
      field?.onTouched();
    }

    setUncontrolledSelectedItem(item);
    setActiveItem(item);
  }

  function handleQueryChange(query: string, event?: React.ChangeEvent<HTMLInputElement>): void {
    onQueryChange?.(query, event);
    setQuery(query);
  }

  function handlePopoverOpening(node: HTMLElement) {
    popoverProps?.onOpening?.(node);
    setActiveItem(selectedItem);
  }

  function handlePopoverClosing(node: HTMLElement) {
    popoverProps?.onClosing?.(node);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
    }
  }

  function renderList(listProps: Core.ItemListRendererProps<T>) {
    const extendedProps = extendItemListRendererProps<T, ItemListRendererProps<T>>(listProps, groupRenderer, noResults, initialContent);
    extendedProps.menuProps = { ...extendedProps.menuProps, ...menuProps };
    return (
      <FormScope controlId="listbox">
        {itemListRenderer(extendedProps)}
      </FormScope>
    );
  }

  function defaultItemListRenderer(listProps: ItemListRendererProps<T>) {
    const createItem = listProps.renderCreateItem();
    const maybeNoResults = !createItem && listProps.filteredItems.length === 0 ? noResults : null;
    const menuContent = listProps.renderItems(maybeNoResults, initialContent);

    if (!menuContent && !createItem) {
      return null;
    }

    return (
      <Menu {...listProps.menuProps} {...menuProps} ulRef={listProps.itemsParentRef}>
        {menuContent}
        {createItem}
      </Menu>
    );
  }
}

export const Suggest = forwardRef(SuggestComponent) as <T>(props: SuggestProps<T> & { ref?: React.Ref<Suggest>}) => JSX.Element;
