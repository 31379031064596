import React, { type PropsWithoutRef, Ref, forwardRef, useRef } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { useAutomation } from "~/hooks";
import { Icon, IconName, IconProps } from "./icon";

export interface MenuItemProps extends PropsWithoutRef<Omit<Core.MenuItemProps, "icon" | "label" | "labelClassName" | "labelElement" | "roleStructure" | "selected">> {
  id?: string;

  icon?: IconName | React.ReactElement<IconProps>;

  iconSize?: number;

  /**
   * Right-aligned content.
   */
  info?: React.ReactNode;

  /**
   * If true, menu item will not be clickable but have a normal appearance.
   * @default false
   */
  readonly?: boolean;

  /**
   * If true, menu item will not be clickable and will have muted appearance.
   * @default false
   */
  passive?: boolean;
}

export const MenuItem = forwardRef((props: MenuItemProps, ref: Ref<HTMLLIElement>) => {
  const { children, text } = props;

  const label = props["aria-label"] ?? (typeof text === "string" ? text : undefined);

  const innerRef = useRef<HTMLLIElement>(null);

  const { id } = useAutomation({ ...props, label }, "menuitem");

  return (
    <Core.MenuItem
      ref={Core.mergeRefs(ref, innerRef)}
      aria-label={label}
      {...toNativeProps(props)}
      id={id}
      roleStructure="menuitem"
    >
      {children}
    </Core.MenuItem>
  );
});

MenuItem.displayName = "MenuItem";

export function toNativeProps(props: MenuItemProps & React.AnchorHTMLAttributes<HTMLAnchorElement>): Core.MenuItemProps & React.AnchorHTMLAttributes<HTMLAnchorElement> {
  const {
    active,
    htmlTitle = "",
    passive,
    readonly,
    disabled: controlledDisabled,
    shouldDismissPopover = true,
    className,
    icon,
    iconSize,
    info,
    onClick,
    ...restProps
  } = props;

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} size={iconSize} />
    : undefined;

  const disabled = controlledDisabled || passive || readonly;

  return {
    ...restProps,
    icon: nativeIcon,
    disabled,
    shouldDismissPopover,
    className: classnames({
      passive,
      readonly,
      [Core.Classes.ACTIVE]: active,
    }, className),
    onClick: disabled ? undefined : onClick,
    label: undefined,
    labelElement: info,
    active,
    htmlTitle,
  };
}
