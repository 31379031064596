import type { Practice } from "@remhealth/apollo";
import { isReleased, useUserSession } from "@remhealth/host";

export function useAllowZeroDurationNonShowNotes(): boolean {
  const session = useUserSession();
  return allowZeroDurationNonShowNotes(session.practice);
}

export function allowZeroDurationNonShowNotes(practice: Practice): boolean {
  switch (practice.product) {
    case "myAvatar": return isReleased(practice, "myAvatarZeroDuration");
    default: return true;
  }
}
