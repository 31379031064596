import { useEffect, useMemo, useState } from "react";
import { first } from "lodash-es";
import classNames from "classnames";
import {
  Encounter,
  GroupNote,
  Instant,
  ItineraryTopic,
  type MultiNote,
  Note,
  NoteDefinition,
  NoteSection,
  NoteSectionForm,
  NoteSectionFormat,
  ParticipantRole,
  Patient,
  PatientNote,
  Practitioner,
  Reference,
  VisitStatus,
  isGroupNote,
  isPatientNote,
  systems
} from "@remhealth/apollo";
import { useDateFormatter, useReleaseCheck, useUserSession } from "@remhealth/host";
import { MediaImage, getUserNameAndCredentials, useErrorHandler, useStore, useStoreItem } from "@remhealth/core";
import { Spinner, useAbort } from "@remhealth/ui";
import { PatientBanner } from "~/avatars/patientBanner";
import { Text } from "~/text";
import { expandNoteSectionForms, fetchCoverages, fetchGuardian, getVisitStatus } from "~/utils";
import { usePatientAccessor } from "~/contexts";
import { CarePlanSessionInfo } from "~/goals/carePlanSessionInfo";
import { NoteContext, NoteContextProvider } from "~/notes/contexts";
import { SessionTimeSectionContent, SignatureCard, TextSectionContent } from "./sections";
import { InaccessibleNote } from "./inaccessibleNote";
import { NoteContentHeaderInfo } from "./noteContentHeaderInfo";
import { NoteReviewers } from "./noteReviewers";
import { NoteSectionContent } from "./noteSectionContent";
import { Container, SectionHeader } from "./sections/common.styles";
import {
  GroupAdmin,
  GroupSectionsContainer,
  NoteContentBody,
  NoteHeader,
  PatientBannerContainer,
  SectionsArea,
  Signature,
  SignatureSection,
  SignatureWrapper,
  SignaturesContainer,
  SpinnerContainer,
  SubHeader
} from "./noteContent.styles";

export interface NoteContentProps {
  note: PatientNote;
  multiNote?: MultiNote;
  hideHeader?: boolean;
  hidePatientSignatures?: boolean;
  isPatientView?: boolean;
  className?: string;
  groupPatients?: Reference<Patient>[];
  hidePatientBanner?: boolean;
  showGroupInfo?: boolean;
  onLoadingChange?: (loading: boolean) => void;
  onLoad?: (data: NoteContentData) => void;
}

export interface NoteContentData {
  encounter: Encounter;
  patient: Patient | null;
  signer: Practitioner | null;
  multiNote: MultiNote | undefined;
  definition: NoteDefinition;
}

export const NoteContent = (props: NoteContentProps) => {
  const {
    className,
    hideHeader = false,
    groupPatients,
    isPatientView = false,
    hidePatientSignatures = false,
    hidePatientBanner = false,
    showGroupInfo = true,
    onLoadingChange,
    onLoad,
  } = props;

  const store = useStore();
  const session = useUserSession();
  const note = useStoreItem(store.notes, props.note);
  const abort = useAbort();
  const handleError = useErrorHandler();
  const patientAccessor = usePatientAccessor();

  const [encounter, setEncounter] = useState<Encounter>();
  const [signer, setSigner] = useState<Practitioner | undefined>();
  const [multiNote, setMultiNote] = useState<MultiNote | undefined>(props.multiNote);
  const [patient, setPatient] = useState<Patient>();
  const [noteContext, setNoteContext] = useState<NoteContext>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dates = useDateFormatter();
  const isMyAvatarDocRoutingReleased = useReleaseCheck("myAvatarDocRouting");

  const isPatientAccessible = useMemo(() => {
    if (note.subject.resource) {
      return !!patientAccessor.test(note.subject.resource);
    }
    return false;
  }, [note.id, patientAccessor.openedPatients]);

  useEffect(() => {
    fetchData(note);
    return store.notes.onItemSet(note.subject.id, note.id, handleNoteSet);
  }, [note, isPatientAccessible]);

  if (isLoading || !encounter) {
    return <SpinnerContainer><Spinner intent="primary" /></SpinnerContainer>;
  }

  const groupSummarySection = multiNote?.sections.find(s => s.format === NoteSectionFormat.Text
    && s.type?.codings.some(c => c.code === getVisitStatus(encounter.status)
      && c.system === systems.visitStatus));

  const groupSessionTimeSection = multiNote?.sections.find(s => s.format === NoteSectionFormat.SessionTime);

  const { signatures, overrideSessionInformation, patientSignatures } = note;
  const signature = first(signatures);
  const hasSesstionTimeSection = note.sections.some(s => s.format === NoteSectionFormat.SessionTime);
  const groupAdmin = note.participants.find(p => p.role === "Coordinator");

  return (
    <NoteContentBody className={classNames("note-content", className)}>
      {!hideHeader && renderHeader(encounter, note)}
      <SectionsArea>
        {!isPatientView && isGroupNote(multiNote) && isPatientAccessible && showGroupInfo && (
          <GroupSectionsContainer>
            {groupAdmin && renderGroupAdmin(groupAdmin.individual.display ?? "")}
            {groupSessionTimeSection && renderVisitTime(note, multiNote, groupSessionTimeSection)}
            {groupSummarySection && renderGroupTextSection(groupSummarySection)}
            {encounter.topics.length > 0 && renderGroupSession(encounter.topics)}
          </GroupSectionsContainer>
        )}
        {note.derivedFrom?.resource?.partOf && <CarePlanSessionInfo carePlanActivities={note.derivedFrom.resource.carePlanActivities} />}
        {renderSections(encounter)}
        {!hidePatientSignatures && note.patientSignatures.length !== 0 && renderPatientSignatures()}
        {isMyAvatarDocRoutingReleased && note.reviewers.length !== 0 && renderReviewers()}
      </SectionsArea>
      {signature && signer && isPatientAccessible && (
        <SignatureSection>
          {signature.signatureImage
            ? (
              <>
                {Text.Date}: {dates.dateTime(Instant.toDate(signature.signed))}
                <Signature>Clinical Signature</Signature>
                <SignatureWrapper>
                  <MediaImage maxHeight={100} maxWidth={250} media={signature.signatureImage} />
                </SignatureWrapper>
                <Signature>{getUserNameAndCredentials(session.practice, signer)}</Signature>
              </>
            ) : (
              <Signature>Electronically Signed by: {getUserNameAndCredentials(session.practice, signer)} on {dates.dateTime(Instant.toDate(signature.signed))}</Signature>
            )}
        </SignatureSection>
      )}
    </NoteContentBody>
  );

  function renderVisitTime(note: Note, groupNote: GroupNote, section: NoteSection) {
    const isShowStatus = getVisitStatus(note.encounter.resource?.status) === VisitStatus.Show;
    if (!isShowStatus) {
      return;
    }
    if (!overrideSessionInformation) {
      return (
        <SessionTimeSectionContent
          key={section.name}
          encounter={note.encounter.resource}
          name={section.name}
          note={groupNote}
        />
      );
    } else if (!hasSesstionTimeSection) {
      return (
        <SessionTimeSectionContent
          key={section.name}
          encounter={note.encounter.resource}
          name={section.name}
          note={note}
        />
      );
    }
    return;
  }

  function renderSections(encounter: Encounter) {
    if (isPatientAccessible && patient && noteContext) {
      return (
        <NoteContextProvider context={noteContext}>
          {multiNote && multiNote.sections.map(s => s.format === NoteSectionFormat.Form ? renderSection(encounter, multiNote, s) : null)}
          {note.sections.map(s => renderSection(encounter, note, s))}
        </NoteContextProvider>
      );
    }

    return <InaccessibleNote note={note} onRequestAccessClick={handleRequestAccessClick} />;
  }

  function renderSection(encounter: Encounter, note: Note, section: NoteSection) {
    return (
      <NoteSectionContent
        key={section.name}
        encounter={encounter}
        isPatientView={isPatientView}
        note={note}
        section={section}
      />
    );
  }

  function renderHeader(encounter: Encounter, note: PatientNote) {
    return (
      <NoteHeader>
        <SubHeader>
          {multiNote?.subject.display && <div>{multiNote.subject.display}</div>}
          {!hidePatientBanner && (
            <PatientBannerContainer>
              <PatientBanner patient={note.subject} />
            </PatientBannerContainer>
          )}
        </SubHeader>
        <NoteContentHeaderInfo className="note-header" encounter={encounter} note={note} />
      </NoteHeader>
    );
  }

  function renderReviewers() {
    return <NoteReviewers reviewers={note.reviewers} />;
  }

  function renderPatientSignatures() {
    return (
      <Container>
        <SectionHeader>{Text.Signatures}</SectionHeader>
        <SignaturesContainer>
          {patientSignatures.map((signature, index) => <SignatureCard key={index} signature={signature} />)}
        </SignaturesContainer>
      </Container>
    );
  }

  async function handleRequestAccessClick() {
    if (multiNote && isGroupNote(multiNote) && groupPatients && groupPatients.length > 0) {
      const patients = groupPatients.filter(p => p.resource && !patientAccessor.test(p.resource));
      await patientAccessor.assertAll(patients);
    } else if (isPatientNote(note)) {
      await patientAccessor.assert(note.subject);
    }
  }

  async function fetchData(note: PatientNote) {
    setIsLoading(true);
    onLoadingChange?.(true);

    try {
      const encounter = await loadEncounter(note);

      if (!isPatientAccessible) {
        return;
      }

      const [patient, definition, multiNote, signer] = await Promise.all([
        loadPatient(note),
        loadNoteDefinition(note),
        loadMultiNote(note),
        loadSigner(note),
        expandForms(note.sections.flatMap(s => s.form ?? [])),
      ]);

      if (multiNote) {
        await expandForms(multiNote.sections.flatMap(s => s.form ?? []));
      }

      if (note.derivedFrom) {
        await store.carePlanOutcomes.expand(note.derivedFrom, { abort: abort.signal });
      }

      if (patient) {
        const [guardian, insurances] = await Promise.all([
          loadGuardian(patient),
          loadInsurances(patient),
        ]);

        setNoteContext({
          patient,
          group: isGroupNote(multiNote) ? multiNote?.subject : undefined,
          composition: note.partOfComposition ?? note.composition,
          episodeOfCare: encounter.episodeOfCare,
          definition,
          insurances,
          guardian,
          noteId: note.id,
          practitioner: note.participants.find(p => p.role === ParticipantRole.PrimaryPerformer)?.individual.resource,
          visitDate: encounter.period.start,
          program: encounter.program,
          location: encounter.location?.location,
          locationRole: encounter.location?.role,
          serviceLocation: encounter.location?.kind,
          serviceType: encounter.serviceType,
          narrativeOverrideLinkId: definition.narrativeDestination,
          period: encounter.period,
          visitStatus: getVisitStatus(encounter.status),
        });

        onLoad?.({ encounter, patient, definition, multiNote, signer });
      }
    } finally {
      setIsLoading(false);
      onLoadingChange?.(false);
    }
  }

  async function loadEncounter(note: PatientNote) {
    try {
      const response = await store.encounters.expand(note.encounter, {
        abort: abort.signal,
      });
      setEncounter(response);
      return response;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }

  async function loadMultiNote(note: PatientNote) {
    if (props.multiNote) {
      return props.multiNote;
    }

    if (note.partOf) {
      const multiNote = await store.notes.expand(note.partOf, {
        abort: abort.signal,
      });
      setMultiNote(multiNote as MultiNote);
      return multiNote as MultiNote;
    }

    setMultiNote(undefined);
    return undefined;
  }

  async function loadNoteDefinition(note: PatientNote) {
    return await store.noteDefinitions.expand(note.definition, { abort: abort.signal });
  }

  async function expandForms(references: Reference<NoteSectionForm>[]) {
    await expandNoteSectionForms(store, references, abort.signal);
  }

  async function loadPatient(note: PatientNote) {
    const openPatient = await patientAccessor.test(note.subject);
    if (openPatient) {
      setPatient(openPatient.patient);
      return openPatient.patient;
    }

    setPatient(undefined);
    return null;
  }

  async function loadSigner(note: PatientNote) {
    const signature = first(note.signatures);
    if (signature?.signer) {
      const signer = await store.practitioners.expand(signature.signer as Reference<Practitioner>, { abort: abort.signal });
      setSigner(signer);
      return signer;
    }

    setSigner(undefined);
    return null;
  }

  async function loadGuardian(patient: Patient) {
    return await fetchGuardian(store.relatedPeople, patient, abort.signal);
  }

  async function loadInsurances(patient: Patient) {
    const coverages = await fetchCoverages(store.coverages, patient, abort.signal);
    return coverages.flatMap(c => c.payor.id ? c.payor : []);
  }

  function handleNoteSet(item: Note) {
    fetchData(item as PatientNote);
  }
};

function renderGroupTextSection(section: NoteSection) {
  if (!section) {
    return null;
  }

  return (
    <TextSectionContent key={section.name} name={section.name} text={section.text} />
  );
}

function renderGroupSession(topics: ItineraryTopic[]) {
  return (
    <Container>
      <SectionHeader>{Text.GroupTopics}</SectionHeader>
      <ul>
        {topics.map(t => (<li key={t.name}>{t.name} - {t.description}</li>))}
      </ul>
    </Container>
  );
}

function renderGroupAdmin(display: string) {
  return (
    <Container>
      <SectionHeader>{Text.Administrator}</SectionHeader>
      <GroupAdmin>{display}</GroupAdmin>
    </Container>
  );
}
