import React, { PropsWithChildren, ReactElement, Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import classnames from "classnames";
import { Classes } from "@blueprintjs/core";
import { ItemsEqualComparator, executeItemsEqual } from "@blueprintjs/select";
import { FilterKeep, FilterRemove } from "@remhealth/icons";
import { AsyncQuery } from "~/utils";
import { useAutomation } from "../hooks";
import { AsyncMultiSelect } from "./asyncMultiSelect";
import { Button } from "./button";
import { ItemInfoRenderer, ListOptionRenderer } from "./useListItems";
import { getComponentId } from "./formScope";
import {
  ButtonRow,
  Container,
  ContentHeader,
  EmptyContainer,
  FilterPopover
} from "./columnSuggestFilter.styles";

export interface ColumnSuggestPopoverProps<T> {
  "aria-label": string;
  queryable: AsyncQuery<T>;
  optionRenderer: ListOptionRenderer<T>;
  infoRenderer?: ItemInfoRenderer<T>;
  tagRenderer?: (item: T) => React.ReactNode;
  itemsEqual: ItemsEqualComparator<T>;
  noSelectionsContent: JSX.Element | string;
  fetchOnBlankQuery?: boolean;
  defaultSelectedItems?: T[];
  selectedItems?: T[];
  onSelectedItemsChange: (selectedItems: T[]) => void;
  onLeftIconClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface ColumnSuggestPopover<T> {
  reset(): void;
}

function ColumnSuggestPopoverComponent<T>(props: PropsWithChildren<ColumnSuggestPopoverProps<T>>, ref: Ref<ColumnSuggestPopover<T>>) {
  const {
    children,
    fetchOnBlankQuery,
    optionRenderer,
    infoRenderer,
    tagRenderer,
    queryable,
    itemsEqual,
    noSelectionsContent,
    defaultSelectedItems = [],
    selectedItems: controlledSelectedItems,
    onSelectedItemsChange,
    onLeftIconClick,
  } = props;

  const { label, id } = useAutomation(props, "column-filter");
  const menuId = getComponentId(id, "listbox");

  const [selectedItems, setSelectedItems] = useState<T[]>(defaultSelectedItems);

  const inputRef = useRef<HTMLInputElement>(null);
  const confirmedItems = useRef<T[]>(selectedItems);

  const hasFiltered = selectedItems.length > 0;
  const hasChanges = selectedItems.length !== confirmedItems.current.length
    || selectedItems.some(i => !confirmedItems.current.includes(i))
    || confirmedItems.current.some(i => !selectedItems.includes(i));

  useImperativeHandle(ref, () => ({
    reset: handleClear,
  }));

  useEffect(() => {
    if (controlledSelectedItems) {
      confirmedItems.current = controlledSelectedItems;
      setSelectedItems(controlledSelectedItems);
    }
  }, [controlledSelectedItems]);

  const content = (
    <Container data-filter-menu id={menuId}>
      <ContentHeader>
        <AsyncMultiSelect<T>
          fill
          aria-label={label}
          fetchOnBlankQuery={fetchOnBlankQuery}
          infoRenderer={infoRenderer}
          itemsEqual={itemsEqual}
          leftIcon="search"
          optionRenderer={optionRenderer}
          popoverProps={{
            usePortal: false,
          }}
          queryable={queryable}
          selectedItems={selectedItems}
          tagInputProps={{
            id,
            inputProps: {
              name: "filter-search",
            },
            inputRef,
          }}
          tagRenderer={tagRenderer}
          onItemRemove={handleItemRemove}
          onItemSelect={handleItemSelect}
          onLeftIconClick={onLeftIconClick}
        />
      </ContentHeader>
      {selectedItems.length === 0 && (
        <EmptyContainer>{noSelectionsContent}</EmptyContainer>
      )}
      {(hasFiltered || hasChanges) && (
        <ButtonRow>
          {hasFiltered && (
            <Button
              data-filter-clear
              minimal
              className={classnames(Classes.POPOVER_DISMISS, "clear")}
              icon={<FilterRemove />}
              intent="primary"
              label="Clear"
              onClick={handleClear}
            />
          )}
          {hasChanges && (
            <Button
              data-filter-button
              minimal
              className={classnames(Classes.POPOVER_DISMISS, "filter")}
              icon={<FilterKeep />}
              intent="primary"
              label="Apply"
            />
          )}
        </ButtonRow>
      )}
    </Container>
  );

  return (
    <FilterPopover content={content} placement="bottom-start" onClosed={handleClosed} onOpened={handleSuggestedOpened}>
      {children}
    </FilterPopover>
  );

  function handleItemSelect(item: T, event?: React.SyntheticEvent<HTMLElement>) {
    event?.stopPropagation();

    if (!selectedItems.some(i => executeItemsEqual(itemsEqual, i, item))) {
      setSelectedItems([...selectedItems, item]);
    }
  }

  function handleItemRemove(item: T) {
    const updatedItems = selectedItems.filter(i => !executeItemsEqual(itemsEqual, i, item));
    setSelectedItems(updatedItems);
  }

  function handleClosed() {
    if (hasChanges) {
      handleConfirm(selectedItems);
    }
  }

  function handleClear() {
    setSelectedItems([]);
  }

  function handleConfirm(newItems: T[]) {
    confirmedItems.current = newItems;
    onSelectedItemsChange(newItems);
  }

  function handleSuggestedOpened() {
    inputRef.current?.focus();
  }
}

export const ColumnSuggestPopover = forwardRef(ColumnSuggestPopoverComponent) as <T>(props: PropsWithChildren<ColumnSuggestPopoverProps<T>> & { ref?: React.Ref<ColumnSuggestPopover<T>> }) => ReactElement;
