import React, { useState } from "react";
import classnames from "classnames";
import { CreateNewItem, getCreateNewItem } from "@blueprintjs/select";
import type { DataAttributes } from "~/types";
import { FormField, getIntent } from "../utils/form";
import { useAutomation, useUpdateEffect } from "../hooks";
import { Select, SelectProps } from "./select";
import { InputGroup, InputGroupProps } from "./inputGroup";
import { IconButton } from "./button";
import { FormScope, getComponentId } from "./formScope";
import { MenuProps } from "./menu";
import { SelectPopoverProps, useListItems } from "./useListItems";

type PropOmissions =
  | "activeItem"
  | "inputProps"
  | "menuProps"
  | "scrollToActiveItem"
  | "onActiveItemChange"
  | "onItemSelect";

type LiftedInputProps = Pick<InputGroupProps,
  | "autoFocus"
  | "aria-label"
  | "disabled"
  | "intent"
  | "leftIcon"
  | "leftElement"
  | "rightElement"
  | "minimal"
  | "name"
  | "id"
  | "readOnly"
  | "placeholder"
  | "soft"
  | "large"
  | "clearable"
  | "onLeftIconClick"
>;

export interface SelectInputProps<T> extends Omit<SelectProps<T>, PropOmissions>, LiftedInputProps, DataAttributes {
  /**
   * Props for the Input rendered within the select input.
   */
  inputProps?: Omit<InputGroupProps, keyof LiftedInputProps | "type" | "field">;

  menuProps?: MenuProps;

  field?: FormField<T | undefined>;

  /**
   * If controlled, will be the item selected.  Must set `onSelectedItemChange` handler.
   */
  selectedItem?: T | null;

  /**
   * Default selected item for when the component is first rendered.  Defaults to undefined.  Ignored if `selectedItem` is set.
   */
  defaultSelected?: T | null;

  /**
   * If true, defaults the `inputProps.rightElement` to a clear button that will clear the selection.
   * Ignored if `inputProps.rightElement` is provided.
   * @default false
   */
  clearable?: boolean;

  /**
   * Whether the component should take up the full width of its container.
   */
  fill?: boolean;

  /**
   * Invoked when an item is selected.  Note that this does not get called if the selection is cleared.  Use `onSelectedItemChange` for selection changes.
   */
  onItemSelect?: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * Invoked when the selection changes or is cleared.
   */
  onSelectedItemChange?: (selectedItem: T | null) => void;
}

export function SelectInput<T>(props: SelectInputProps<T>) {
  let {
    autoFocus,
    className,
    disabled: controlledDisabled,
    fill,
    minimal = false,
    soft = false,
    large = false,
    placeholder,
    leftIcon,
    leftElement,
    rightElement,
    defaultSelected = null,
    selectedItem: controlledSelectedItem,
    onLeftIconClick,
    onItemSelect,
    onSelectedItemChange,
    inputProps = {},
    menuProps: controlledMenuProps,
    popoverProps: controlledPopoverProps,
    clearable = false,
    field,
    name = field?.name,
    readOnly = field?.readOnly,
    ...restProps
  } = props;

  const { label, id, controlId, errorId } = useAutomation(props);
  const inputId = getComponentId(id, "target");
  const clearButtonId = getComponentId(id, "clear");
  const expandButtonId = getComponentId(id, "expand");

  const [uncontrolledSelectedItem, setUncontrolledSelectedItem] = useState<T | null>(defaultSelected);
  const selectedItem = controlledSelectedItem !== undefined ? controlledSelectedItem : field ? field.value ?? null : uncontrolledSelectedItem;
  const [activeItem, setActiveItem] = useState<T | CreateNewItem | null>(selectedItem);

  let disabled = controlledDisabled ?? false;

  if (field?.disabled) {
    disabled = true;
  }

  let extraRightElement: JSX.Element | undefined;

  if (clearable && selectedItem) {
    extraRightElement = (
      <IconButton
        minimal
        aria-label={label ? `Clear ${label}` : "Clear"}
        disabled={disabled}
        icon="cross"
        id={clearButtonId}
        square={!inputProps.round}
        onClick={handleClear}
      />
    );
  } else if (!rightElement) {
    extraRightElement = (
      <IconButton
        minimal
        aria-label={label ? `Expand ${label}` : "Expand"}
        disabled={disabled}
        icon="caret-down"
        id={expandButtonId}
        square={!inputProps.round}
        tabIndex={-1}
      />
    );
  }

  if (extraRightElement) {
    rightElement = (
      <>
        {rightElement}
        {extraRightElement}
      </>
    );
  }

  const intent = getIntent(field) ?? props.intent;
  const customizedInputProps: Partial<InputGroupProps> = {
    ...inputProps,
    "aria-errormessage": errorId,
    "aria-invalid": field?.error ? true : undefined,
    "aria-label": label,
    "id": inputId,
    "defaultValue": undefined,
    "readOnly": readOnly ?? field?.readOnly,
    disabled,
    autoFocus,
    intent,
    name,
    minimal,
    fill,
    large,
    leftIcon,
    leftElement,
    rightElement,
    placeholder,
    "onLeftIconClick": onLeftIconClick ? handleLeftIconClick : undefined,
  };

  const { textRenderer } = useListItems(props, []);

  let popoverProps: Partial<SelectPopoverProps> = controlledPopoverProps ?? {};

  if (disabled || field?.readOnly) {
    popoverProps = {
      ...popoverProps,
      isOpen: false,
    };
  }

  useUpdateEffect(() => {
    if (props.selectedItem) {
      field?.onChange(props.selectedItem);
      setUncontrolledSelectedItem(props.selectedItem ?? null);
    }
  }, [props.selectedItem]);

  // Fix issue with undefined acting like controlled value
  if (popoverProps && popoverProps.isOpen === undefined) {
    delete popoverProps.isOpen;
  }

  const value = selectedItem ? textRenderer(selectedItem) : "";

  return (
    <FormScope controlId={controlId}>
      <Select<T>
        {...restProps}
        scrollToActiveItem
        activeItem={activeItem}
        aria-label={label}
        className={classnames("select-input", className)}
        disabled={disabled}
        id={id}
        popoverProps={{
          targetTagName: "div",
          ...popoverProps,
          onOpening: handlePopoverOpening,
          onClosing: handlePopoverClosing,
          minimal: popoverProps?.minimal !== false,
        }}
        onActiveItemChange={handleActiveItemChange}
        onItemSelect={handleItemSelect}
        onLeftIconClick={onLeftIconClick}
      >
        <InputGroup {...customizedInputProps} type="button" value={value} />
      </Select>
    </FormScope>
  );

  function handleActiveItemChange(activeItem: T | null, isCreateNewItem: boolean) {
    setActiveItem(isCreateNewItem ? getCreateNewItem() : activeItem);
  }

  function handleItemSelect(item: T, event?: React.SyntheticEvent<HTMLElement>) {
    onItemSelect?.(item, event);
    handleSelectedItemChange(item);
  }

  function handleClear(event: React.MouseEvent): void {
    event.stopPropagation();
    handleSelectedItemChange(null);
  }

  function handleSelectedItemChange(selectedItem: T | null): void {
    onSelectedItemChange?.(selectedItem);

    if (!field?.readOnly && !field?.disabled) {
      field?.onChange(selectedItem ?? undefined);
      field?.onTouched();
    }

    setUncontrolledSelectedItem(selectedItem);
    setActiveItem(selectedItem);
  }

  function handlePopoverOpening(node: HTMLElement) {
    popoverProps?.onOpening?.(node);
    setActiveItem(selectedItem);
  }

  function handlePopoverClosing(node: HTMLElement) {
    popoverProps?.onClosing?.(node);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
    }
  }

  function handleLeftIconClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    onLeftIconClick?.(event);
    event.stopPropagation();
  }
}
