import { ReactElement, useMemo } from "react";
import {
  useApollo,
  usePracticePreferences,
  useProductFlag,
  useReleaseCheck,
  useUserProfile,
  useUserSession
} from "@remhealth/host";
import {
  ActivePullContext,
  FormContent,
  useEhr,
  useErrorHandler,
  useErrorReporter,
  useLabeling,
  useStore
} from "@remhealth/core";
import {
  Duration,
  Encounter,
  EncounterStatus,
  type GroupNote,
  MultiNote,
  Note,
  NoteComposition,
  NoteDefinition,
  NoteDefinitionType,
  NoteRule,
  ParticipantRole,
  PatientNote,
  SignatureConfiguration,
  VisitStatus,
  isApolloResponseError,
  isGroupNote
} from "@remhealth/apollo";
import { DocRouting, DocumentFile, WarningSign } from "@remhealth/icons";
import { IconProps, createSubscription, useAbort, useCallbackRef, useSubscriptionDispatch, useSubscriptionRef } from "@remhealth/ui";
import { doesServiceTypeRequireUnits, fetchCoverages, getVisitStatus, renderVisitStatus } from "~/utils";
import { Text } from "~/text";
import { usePatientAccessor } from "~/contexts";
import { isExternalAppointment } from "~/appointments/utils";
import { loadNoteRules, useNoteRules } from "~/notes/rules/useNoteRules";
import { type MultiNoteForm, NoteForm, isMultiNoteForm } from "~/notes/types";
import { answerValueHasValue } from "~/questionnaire/utils";
import { noteFormSchema } from "~/notes/schema";
import { NoteEditPromptsContext, OverlappingNotesAlert } from "~/notes/prompts/noteEditPromptsContext";
import { hasLinkedSectionForm } from "~/questionnaire/flavors";
import { isGuardianSignature, isPatientSignature } from "../utils";
import { getHiddenFormElements } from "../contexts";
import { RuleAlertItem } from "../rules";
import { useAllowZeroDurationNonShowNotes } from "./useAllowZeroDuration";
import { useNoteOverlapCheck } from "./useNoteOverlapCheck";
import { useNoteAuthorizationCheck } from "./useNoteAuthorizationCheck";
import { type NoteSectionValidationContext, durationRequired, isFuturePeriod, isNoteSectionValid } from "./validation";

type NoteReviewInteractionMode =
  // User is signing an individual note
  "signing" |
  // User is reviewing notes one-by-one in bulk using bulk sign or within a group note
  "reviewing" |
  // User is reviewing many notes via one click, such as Review All Notes
  "bulk-reviewing" |
  // User is reviewing patient viewable sections.
  "patient-signing";

interface NoteReview {
  readonly reviewedNotes: NoteReviewSet;
  /** Runs all note validations. */
  isNoteInvalid(note: PatientNote): Promise<false | string>;
  isGroupNoteValid(note: GroupNote): Promise<boolean>;
  /** Runs all note validations and prepares the note for being signed. */
  reviewNote(note: PatientNote, interactionMode: NoteReviewInteractionMode): Promise<boolean>;
  isSigned(note: PatientNote): boolean;
}

interface NoteFormReview extends NoteReview {
  isNoteFormInvalid(note: PatientNote): false | string;
  isOwner(): boolean;
}

interface NoteValidationOptions {
  requireUnits: boolean;
  requireCategory: boolean;
  requireEnrollment: boolean;
  requireProgram: boolean;
}

export interface NoteWarning {
  message: string;
  warning: boolean;
  icon?: ReactElement<IconProps>;
}

export interface NoteReviewState {
  reviewedNotes: NoteReviewSet;
  noteWarnings: Map<string, NoteWarning>;
}

export interface NoteReviewSet {
  readonly size: number;
  has(noteId: string): boolean;
  add(noteId: string): this;
  delete(noteId: string): boolean;
  clear(): void;
}

export const { context: NoteReviewContext, Provider: NoteReviewProvider } = createSubscription<NoteReviewState>({
  reviewedNotes: new Set<string>(),
  noteWarnings: new Map(),
});

export function useNoteReview(): NoteReview;
export function useNoteReview(form: FormContent<NoteForm> | FormContent<MultiNoteForm>): NoteFormReview;
export function useNoteReview(form?: FormContent<NoteForm> | FormContent<MultiNoteForm>) {
  const apollo = useApollo();
  const abort = useAbort();
  const labels = useLabeling();
  const store = useStore();
  const ehr = useEhr();
  const handleError = useErrorHandler();
  const reportError = useErrorReporter();
  const patientAccessor = usePatientAccessor();
  const session = useUserSession();
  const unmetRules = useNoteRules(form, true);
  const user = useUserProfile();
  const preferences = usePracticePreferences();
  const { noteOverlapCheck, allowNoteOverlapCheck } = useNoteOverlapCheck();
  const { noteAuthorizationCheck, allowNoteAuthorizationCheck } = useNoteAuthorizationCheck();

  const setPromptsState = useSubscriptionDispatch(NoteEditPromptsContext);
  const setActivePull = useSubscriptionDispatch(ActivePullContext);
  const noteReviewState = useSubscriptionRef(NoteReviewContext);

  const isNoteFormInvalidCallback = useCallbackRef(isNoteFormInvalid);
  const isNoteInvalidCallback = useCallbackRef(isNoteInvalid);
  const isGroupNoteValidCallback = useCallbackRef(isGroupNoteValid);
  const isSignedCallback = useCallbackRef(isSigned);
  const reviewNoteCallback = useCallbackRef(reviewNote);

  const myAvatarDocRoutingReleased = useReleaseCheck("myAvatarDocRouting");
  const myEvolvDocRoutingReleased = useReleaseCheck("myEvolvDocRouting");
  const mobilePatientSignatures = useReleaseCheck("MobilePatientSignatures");

  const groupNoteEnrollmentField = useProductFlag("GroupNoteEnrollmentField");
  const hasCategoryField = useProductFlag("ShowNoteCategoryField");
  const hasProgramField = useProductFlag("ShowProgramField");
  const checkNoteTypeGoalsMinimumLevel = useProductFlag("GoalsMinimumLevel");
  const allowZeroDurationNonShowNotes = useAllowZeroDurationNonShowNotes();
  const docRoutingReleased = (session.practice.product === "myAvatar" && myAvatarDocRoutingReleased)
    || (session.practice.product === "myEvolv" && myEvolvDocRoutingReleased);
  const enrollmentOptional = useProductFlag("EnrollmentOptional");

  const reviewedNotes = useMemo<NoteReviewSet>(() => ({
    get size() {
      return noteReviewState.current.reviewedNotes.size;
    },
    has: (noteId: string) => noteReviewState.current.reviewedNotes.has(noteId),
    add: (noteId: string) => updateReviewedNotes(reviewedNotes => reviewedNotes.add(noteId)),
    delete: (noteId: string) => updateReviewedNotes(reviewedNotes => reviewedNotes.delete(noteId)),
    clear: () => updateReviewedNotes(reviewedNotes => reviewedNotes.clear()),
  }), [noteReviewState]);

  if (!form) {
    return {
      isNoteInvalid: isNoteInvalidCallback,
      isGroupNoteValid: isGroupNoteValidCallback,
      isSigned: isSignedCallback,
      reviewNote: reviewNoteCallback,
      reviewedNotes,
    } satisfies NoteReview;
  }

  return {
    isNoteFormInvalid: (note: PatientNote) => isNoteFormInvalidCallback(form, note),
    isOwner: () => isOwner(form),
    isNoteInvalid: isNoteInvalidCallback,
    isGroupNoteValid: isGroupNoteValidCallback,
    isSigned: isSignedCallback,
    reviewNote: reviewNoteCallback,
    reviewedNotes,
  } satisfies NoteFormReview;

  async function reviewNote(note: PatientNote, interactionMode: NoteReviewInteractionMode) {
    // Reset reason
    updateInvalidReason(note.id, undefined);

    const invalidReason = await isNoteInvalid(note, interactionMode === "patient-signing");
    if (invalidReason !== false) {
      return createReviewError(note.id, invalidReason, interactionMode);
    }

    if (!await checkAppointmentAlreadyLinked(note, interactionMode)) {
      return false;
    }

    if (!await checkNoteForOverlaps(note, interactionMode)) {
      return false;
    }

    if (!await checkNoteForAuthorization(note, interactionMode)) {
      return false;
    }

    if (!await checkRouting(note, interactionMode)) {
      return false;
    }

    return true;
  }

  function isNoteFormInvalid(form: FormContent<NoteForm> | FormContent<MultiNoteForm>, note: PatientNote): false | string {
    if (isMultiNoteForm(form)) {
      return false;
    }

    if (!note.subject.resource || !patientAccessor.test(note.subject.resource)) {
      return Text.RestrictedPatient(labels);
    }

    const definition = note.definition.resource!;
    const encounter = note.encounter.resource!;

    const multiNote = note.partOf?.resource ? note.partOf?.resource as MultiNote : null;

    if (isGroupNote(multiNote)) {
      if (form.values.groupNoteInvalid) {
        return Text.IncompleteGroupInformation;
      }

      if (preferences.forceGroupNoteServiceOverride) {
        const visitStatus = getVisitStatus(encounter.status);
        const definitionAssignmentServices = multiNote?.definitionAssignments.filter(i => i.service).map(i => i.service!.id) ?? [];
        const parentEncounter = encounter.partOf?.resource;
        if (visitStatus !== VisitStatus.Show && parentEncounter?.serviceType && encounter.serviceType) {
          const isRestrictedService = [...definitionAssignmentServices, parentEncounter.serviceType.id].includes(encounter.serviceType.id);
          if (isRestrictedService) {
            return Text.ForceServiceOverride(labels, renderVisitStatus(visitStatus));
          }
        }
      }

      if (durationRequired(encounter, allowZeroDurationNonShowNotes) && durationIsEmpty(form.values.duration)) {
        return durationIsZero(form.values.duration) ? Text.DurationCannotBeZero : Text.IncompleteGroupInformation;
      }
    }

    const schema = getSchema(note, definition, encounter, multiNote, form.values.hideElementLinkIds);

    try {
      // Yup.Lazy does not implement isValidSync
      schema.validateSync(form.values);
    } catch {
      return Text.IncompleteNote;
    }

    if (!isFutureSessionTime(note, definition.earlySignatureAllowedMinutes)) {
      return Text.FutureSessionEndTime;
    }

    if (!isValidNoteContentLength(note)) {
      return Text.NoteCharacterOverlimit(note.summary?.plainText?.length ?? 0, session.practice.product ?? "Bells");
    }

    if (unmetRules.length > 0) {
      return Text.RuleNotMet;
    }

    return false;
  }

  async function isNoteInvalid(note: PatientNote, patientViewableMode = false): Promise<false | string> {
    // Use latest saved version
    note = (store.notes.get(note.partition, note.id) ?? note) as PatientNote;

    let definition: NoteDefinition;
    try {
      definition = await getNoteDefinition(note);
    } catch (error) {
      reportError(error);
      return "Missing note type.";
    }

    let encounter: Encounter;
    try {
      encounter = await getEncounter(note);
    } catch (error) {
      reportError(error);
      return `Missing ${labels.session}.`;
    }

    const visitStatus = getVisitStatus(encounter.status);
    const multiNote = note.partOf ? await store.notes.expand(note.partOf) as MultiNote : null;

    if (isGroupNote(multiNote)) {
      if (preferences.forceGroupNoteServiceOverride) {
        const parentEncounter = encounter.partOf ? await store.encounters.expand(encounter.partOf) : undefined;
        const definitionAssignmentServices = multiNote?.definitionAssignments.filter(i => i.service).map(i => i.service!.id) ?? [];
        if (visitStatus !== VisitStatus.Show && parentEncounter?.serviceType && encounter.serviceType) {
          const isRestrictedService = [...definitionAssignmentServices, parentEncounter.serviceType?.id].includes(encounter.serviceType.id);
          if (isRestrictedService) {
            return Text.ForceServiceOverride(labels, renderVisitStatus(visitStatus));
          }
        }
      }

      if (durationRequired(encounter, allowZeroDurationNonShowNotes) && !hasDuration(encounter)) {
        return durationIsZero(encounter.duration) ? Text.DurationCannotBeZero : Text.IncompleteGroupInformation;
      }

      if (!await isGroupNoteValid(multiNote)) {
        return Text.IncompleteGroupInformation;
      }
    }

    if (!isFutureSessionTime(note, definition.earlySignatureAllowedMinutes)) {
      return Text.FutureSessionEndTime;
    }

    if (!note.subject.resource || !patientAccessor.test(note.subject.resource)) {
      return Text.RestrictedPatient(labels);
    }

    const coverages = await fetchCoverages(store.coverages, note.subject);

    const requireOptions = getRequireOptions(note, definition, encounter);

    if (!encounter.episodeOfCare) {
      if (requireOptions.requireEnrollment) {
        return Text.NoEnrollments(labels);
      }
    }

    if (requireOptions.requireProgram && !encounter.program) {
      return Text.NoPrograms;
    }

    if (requireOptions.requireCategory && !note.category) {
      return Text.NoNoteCategory(labels);
    }

    const noteSectionForms = await store.noteSectionForms.expand(note.sections.flatMap(s => s.form ?? []));
    await store.noteSectionForms.expand(noteSectionForms.flatMap(f => f.elements.flatMap(e => hasLinkedSectionForm(e) ? e.form : [])));

    const sectionValidationContext: NoteSectionValidationContext = {
      note,
      encounter,
      definition,
      patientViewableMode,
      checkNoteTypeGoalsMinimumLevel,
      allowZeroDurationNonShowNotes,
      patient: note.subject.resource,
      visitDate: encounter.period.start,
      location: encounter.location?.location,
      locationRole: encounter.location?.role,
      serviceLocation: encounter.location?.kind,
      serviceType: encounter.serviceType,
      program: encounter.program,
      insurances: coverages.flatMap(c => c.payor.id ? c.payor : []),
      composition: note.partOfComposition ?? note.composition,
      narrativeOverrideLinkId: definition.narrativeDestination,
      hideElementLinkIds: getHiddenFormElements(note, multiNote),
      period: encounter.period,
      visitStatus: getVisitStatus(encounter.status),
    };

    const sectionsValid = note.sections.every(section => isNoteSectionValid(section, sectionValidationContext));

    if (!sectionsValid) {
      return Text.IncompleteNote;
    }

    if (!patientViewableMode) {
      const signatureConfiguration = note.signatureConfiguration ? await store.signatureConfigurations.expand(note.signatureConfiguration) : undefined;

      if (!isPatientSignaturesValid(note, signatureConfiguration)) {
        return Text.MissingSignature;
      }
    }

    if (mobilePatientSignatures && encounter.patientSignatures.length > 0) {
      return "You have unassigned signatures. Assign or remove the signatures to sign the note."
    }

    if (!isValidNoteContentLength(note)) {
      return Text.NoteCharacterOverlimit(note.summary?.plainText?.length ?? 0, session.practice.product ?? "Bells");
    }

    if (note.partOfComposition !== NoteComposition.Group && encounter.serviceType && encounter.duration && encounter.period) {
      const unmetRules = await loadNoteRules(store.noteRules, encounter, ["SessionDuration", "SpanMidnight"], true, abort.signal);
      if (unmetRules.length > 0) {
        return Text.RuleNotMet;
      }
    }

    return false;
  }

  async function isGroupNoteValid(groupNote: GroupNote): Promise<boolean> {
    // Performance: Skip group note validation if there aren't any form sections
    if (!groupNote.sections.some(s => s.format === "Form" && s.form)) {
      return true;
    }

    await store.noteSectionForms.expand(groupNote.sections.flatMap(s => s.form ?? []));

    const groupEncounter = await store.encounters.expand(groupNote.encounter);
    const sectionValidationContext: NoteSectionValidationContext = {
      note: groupNote,
      encounter: groupEncounter,
      allowZeroDurationNonShowNotes,
      checkNoteTypeGoalsMinimumLevel,
      composition: "Group",
      definition: undefined,
      insurances: [],
      location: groupEncounter.location?.location,
      locationRole: groupEncounter.location?.role,
      narrativeOverrideLinkId: undefined,
      patient: undefined,
      patientViewableMode: false,
      period: undefined,
      program: groupEncounter.program,
      serviceLocation: groupEncounter.location?.kind,
      serviceType: groupEncounter.serviceType,
      visitDate: undefined,
      hideElementLinkIds: undefined,
      visitStatus: getVisitStatus(groupEncounter.status),
    };

    return groupNote.sections.every(section => isNoteSectionValid(section, sectionValidationContext));
  }

  function requestRouting(note: Note) {
    return new Promise<boolean>(resolve => {
      setPromptsState(value => ({
        ...value,
        signing: false,
        signedOutcome: undefined,
        routing: {
          note,
          onCancel: () => resolve(false),
          onComplete: () => {
            updateInvalidReason(note.id, undefined);
            resolve(true);
          },
        },
      }));
    });
  }

  function updateInvalidReason(noteId: string, noteWarning: NoteWarning | undefined) {
    noteReviewState.set(value => {
      const noteWarnings = value.noteWarnings;
      if (!noteWarning) {
        noteWarnings.delete(noteId);
      } else {
        noteWarnings.set(noteId, noteWarning);
      }
      return { ...value, noteWarnings: new Map(noteWarnings) };
    });
  }

  function createReviewWarning(
    noteId: string,
    message: string,
    interactionMode: NoteReviewInteractionMode,
    onOkay?: () => Promise<void>,
    icon?: ReactElement<IconProps>
  ): Promise<boolean> {
    if (interactionMode === "bulk-reviewing") {
      updateInvalidReason(noteId, { message, warning: true, icon });
      return Promise.resolve(false);
    }

    return new Promise<boolean>(resolve => {
      setPromptsState(value => ({ ...value, presignAlert: { content: <p>{message}</p>, onOkay: handleOkay } }));

      async function handleOkay() {
        await onOkay?.();
        resolve(true);
      }
    });
  }

  function createReviewError(
    noteId: string,
    message: string,
    interactionMode: NoteReviewInteractionMode,
    icon?: ReactElement<IconProps>,
    confirmText?: string
  ): false {
    if (interactionMode === "bulk-reviewing") {
      updateInvalidReason(noteId, { message, warning: false, icon });
      return false;
    }

    setPromptsState(value => ({ ...value, presignAlert: { content: <p>{message}</p>, onOkay: undefined, icon, confirmText } }));
    return false;
  }

  // For non-group notes, perform last minute check to make sure appointment is not already linked to another note
  async function checkAppointmentAlreadyLinked(note: PatientNote, interactionMode: NoteReviewInteractionMode): Promise<boolean> {
    if (!note.encounter.resource) {
      return true;
    }

    let { appointment } = note.encounter.resource;

    if (note.partOf || !appointment) {
      return true;
    }

    try {
      appointment = await apollo.appointments.fetchById(appointment.partition, appointment.id);
    } catch (error) {
      if (isApolloResponseError(error) && (error.response.status === 404 || error.response.status === 410)) {
        return createReviewWarning(note.id, Text.ApptNoLongerExists, interactionMode, () => unlinkAppointment(note));
      }

      handleError(error);
      return false;
    }

    try {
      const result = await pullAppointment(appointment.id);
      if (result.outcome === "Deleted" || result.outcome === "NotFound") {
        return createReviewWarning(note.id, Text.ApptNoLongerExists, interactionMode, () => unlinkAppointment(note));
      }
    } catch (error) {
      reportError(error);
    }

    try {
      const updatedAppt = await apollo.appointments.fetchById(appointment.partition, appointment.id);

      if (isExternalAppointment(updatedAppt)) {
        const message = interactionMode === "bulk-reviewing"
          ? Text.ApptAlreadyLinkedWarning(labels)
          : Text.ApptWillBeUnlinkedWarning(labels);
        return createReviewWarning(note.id, message, interactionMode, () => unlinkAppointment(note));
      }

      return true;
    } catch (error) {
      if (isApolloResponseError(error) && (error.response.status === 404 || error.response.status === 410)) {
        return createReviewWarning(note.id, Text.ApptNoLongerExists, interactionMode, () => unlinkAppointment(note));
      }

      handleError(error);
      return false;
    }
  }

  async function checkRouting(note: PatientNote, interactionMode: NoteReviewInteractionMode): Promise<boolean> {
    // Sign dialog will also prompt for routing, so don't need it here if signing
    if (interactionMode === "patient-signing" || interactionMode === "signing") {
      return true;
    }

    // Evolv routing for group notes happens at group note level by primary practitioner
    if (session.practice.product === "myEvolv") {
      if (note.partOf && note.partOfComposition === "Group") {
        const multiNote = await store.notes.expand(note.partOf) as GroupNote;
        if (isNoteRouted(multiNote)) {
          return true;
        }

        if (multiNote.participants.some(p => p.role === ParticipantRole.PrimaryPerformer && p.individual.id === user.id)) {
          if (interactionMode === "bulk-reviewing") {
            return createReviewWarning(note.id, "Select routing approvers", interactionMode, undefined, <DocRouting />);
          }

          return requestRouting(multiNote);
        }
      } else {
        if (interactionMode === "bulk-reviewing" && !isNoteRouted(note)) {
          return createReviewWarning(note.id, "Select routing approvers", interactionMode, undefined, <DocRouting />);
        }

        if (interactionMode === "reviewing") {
          return requestRouting(note);
        }
      }
    } else {
      let documentRoutingEnabled = true;

      if (session.practice.product === "myAvatar") {
        const definition = await store.noteDefinitions.expand(note.definition);
        documentRoutingEnabled = !!definition.documentRoutingEnabled;
      }

      if (docRoutingReleased && documentRoutingEnabled) {
        if (interactionMode === "bulk-reviewing" && !isNoteRouted(note)) {
          return createReviewWarning(note.id, "Select routing approvers", interactionMode, undefined, <DocRouting />);
        }

        if (interactionMode === "reviewing") {
          return requestRouting(note);
        }
      }
    }

    return true;
  }

  async function checkNoteForAuthorization(note: PatientNote, interactionMode: NoteReviewInteractionMode): Promise<boolean> {
    if (interactionMode === "patient-signing") {
      return true;
    }

    if (!await allowNoteAuthorizationCheck(note)) {
      return true;
    }

    if (interactionMode === "bulk-reviewing") {
      return createReviewWarning(note.id, "Check for note authorization", interactionMode, undefined, <DocumentFile />);
    }

    try {
      const { authorizationRules, blockSign } = await noteAuthorizationCheck(note);
      if (authorizationRules.length > 0) {
        const content = (
          <>
            <p>{blockSign ? Text.BlockingUnmetRulesPrompt : Text.UnmetRulesPrompt}</p>
            {authorizationRules.map(renderRulesDisplay)}
          </>
        );

        return new Promise<boolean>(resolve => {
          setPromptsState(value => ({
            ...value,
            presignAlert: {
              content,
              onOkay: () => resolve(false),
              onCancel: () => resolve(true),
              icon: <WarningSign />,
              confirmText: "Okay, I'll fix it",
              cancelText: blockSign ? undefined : "No, sign the note now",
            },
          }));
        });
      }
    } catch (error) {
      reportError(error);
    }

    return true;
  }

  async function checkNoteForOverlaps(note: PatientNote, interactionMode: NoteReviewInteractionMode): Promise<boolean> {
    if ((note.partOf && isGroupNote(note.partOf.resource)) || !note.participants.some(p => !!p.period?.start && !!p.period.end)) {
      return true;
    }

    // If we've already determined there were overlapping notes, don't bother checking again in bulk-reviewing mode
    if (interactionMode === "bulk-reviewing" && note.ignoredOverlappingNotes) {
      return createReviewWarning(note.id, "Has overlapping notes", interactionMode);
    }

    const definition = await store.noteDefinitions.expand(note.definition);
    const sessionTimeSection = definition.sections.find(s => s.format === "SessionTime");
    if (!sessionTimeSection || !allowNoteOverlapCheck(note)) {
      return true;
    }

    let performOverlapCheck: boolean;

    if (sessionTimeSection.includeInPatientView) {
      // If session time is in patient view, then overlap check as long as there are no patient sigs
      // e.g. during patient signing or user signing
      performOverlapCheck = note.patientSignatures.length === 0;
    } else {
      // If session time is only clinician viewable, then do not overlap check during patient signing
      performOverlapCheck = interactionMode !== "patient-signing";
    }

    if (!performOverlapCheck) {
      return true;
    }

    try {
      const { overlappingNotes, overlapRule } = await noteOverlapCheck(note);
      if (overlappingNotes.length > 0) {
        if (interactionMode === "bulk-reviewing") {
          return createReviewWarning(note.id, "Has overlapping notes", interactionMode);
        }

        // If validation rule, we prevent signing
        if (overlapRule) {
          const content = (
            <>
              <p>{Text.BlockingUnmetRulesPrompt}</p>
              {renderRulesDisplay(overlapRule)}
            </>
          );

          setPromptsState(value => ({
            ...value,
            presignAlert: {
              content,
              icon: <WarningSign />,
              confirmText: "Okay, I'll fix it",
            },
          }));
          return false;
        }

        // If no validation rule, then we just give a warning instead
        return new Promise<boolean>(resolve => {
          const overlappingNotesAlert: OverlappingNotesAlert = {
            note,
            overlappingNotes,
            onCancel: () => resolve(false),
            onComplete: () => resolve(true),
          };
          setPromptsState(value => ({ ...value, ignoredOverlappingNotes: false, overlappingNotesAlert }));
        });
      }
    } catch (error) {
      reportError(error);
    }

    return true;
  }

  async function pullAppointment(appointmentId: string) {
    setActivePull(state => ({ ...state, appointmentIds: new Set([...state.appointmentIds, appointmentId]) }));
    try {
      return await ehr.pull("Appointment", appointmentId);
    } finally {
      setActivePull(state => ({ ...state, appointmentIds: new Set([...state.appointmentIds].filter(id => id !== appointmentId)) }));
    }
  }

  async function unlinkAppointment(note: Note) {
    const encounter = await store.encounters.expand(note.encounter);
    if (encounter.appointment) {
      encounter.appointment = undefined;
      await store.encounters.upsertAsync(encounter);
    }
  }

  function getSchema(note: PatientNote, definition: NoteDefinition, encounter: Encounter, multiNote: MultiNote | null, hideElementLinkIds: Set<string>) {
    const visitStatus = getVisitStatus(note.encounter.resource?.status);
    const requireGroupServiceOverride = !!note.partOf && isGroupNote(multiNote) && preferences.forceGroupNoteServiceOverride && visitStatus !== VisitStatus.Show;

    const requireOptions = getRequireOptions(note, definition, encounter);

    return noteFormSchema({
      ...requireOptions,
      note,
      multiNote,
      validationMode: "strict",
      labels,
      hasCarePlans: false,
      checkNoteTypeGoalsMinimumLevel: false,
      allowZeroDurationNonShowNotes,
      requireGroupServiceOverride,
      hideElementLinkIds,
    });
  }

  async function getNoteDefinition(note: PatientNote) {
    return await store.noteDefinitions.expand(note.definition, {
      abort: abort.signal,
    });
  }

  async function getEncounter(note: PatientNote) {
    const response = await store.encounters.expand(note.encounter, {
      abort: abort.signal,
    });
    return response;
  }

  // Verify note sessions and period are not in the future
  function isFutureSessionTime(note: Note, earlySignatureAllowedMinutes: number) {
    // Use latest saved version
    note = store.notes.get(note.partition, note.id) ?? note;

    // Verify if the note's sign date/time is prior to the note session's end date
    const { period, status } = note.encounter.resource!;
    const canHaveFutureSessionTime = status === EncounterStatus.Cancelled;

    if (!canHaveFutureSessionTime && isFuturePeriod(period, earlySignatureAllowedMinutes)) {
      return false;
    }

    return true;
  }

  function isSigned(note: PatientNote) {
    return note.status === "Final" || note.status === "Amended";
  }

  function isOwner(form: FormContent<NoteForm> | FormContent<MultiNoteForm>) {
    return form.values.owner?.id === session.person.id;
  }

  function isValidNoteContentLength(note: PatientNote) {
    if (session.practice.product !== "myAvatar" || !note.summary?.plainText) {
      return true;
    }

    return note.summary.plainText.length <= 32700;
  }

  function hasDuration(encounter: Encounter) {
    return !durationIsEmpty(encounter.duration);
  }

  function durationIsEmpty(duration?: Duration) {
    return !duration || Duration.toLuxon(duration).as("minutes") === 0;
  }

  function durationIsZero(duration?: Duration) {
    return duration && Duration.toLuxon(duration).as("minutes") === 0;
  }

  function updateReviewedNotes<T>(action: (reviewedNotes: NoteReviewSet) => T) {
    const reviewedNotes = noteReviewState.current.reviewedNotes;
    const result = action(reviewedNotes);
    noteReviewState.set(state => ({ ...state, reviewedNotes }));
    return result;
  }

  function renderRulesDisplay(rule: NoteRule) {
    if (rule.criteria.type === "SessionOverlap" || rule.criteria.type === "Authorization") {
      return (
        <div key={rule.id}>
          <RuleAlertItem rule={rule} />
        </div>
      );
    }
    return;
  }

  function getRequireOptions(note: PatientNote, definition: NoteDefinition, encounter: Encounter): NoteValidationOptions {
    if (definition.type === NoteDefinitionType.Assessment) {
      return {
        requireUnits: false,
        requireCategory: false,
        requireEnrollment: !definition.nonEpisodic,
        requireProgram: false,
      };
    }

    return {
      requireUnits: encounter.serviceType?.resource ? doesServiceTypeRequireUnits(encounter.serviceType.resource) : false,
      requireCategory: hasCategoryField,
      requireEnrollment: enrollmentOptional ? false : isGroupNote(note.partOf?.resource) ? groupNoteEnrollmentField : true,
      requireProgram: hasProgramField,
    };
  }
}

function isPatientSignaturesValid(note: PatientNote, signatureConfiguration: SignatureConfiguration | undefined) {
  if (signatureConfiguration) {
    if (signatureConfiguration.requirePatientSignature && !note.patientSignatures.some(isPatientSignature)) {
      return false;
    }

    if (signatureConfiguration.requireGuardianSignature && !note.patientSignatures.some(isGuardianSignature)) {
      return false;
    }
  }

  const missingSignatureElements = note.sections.some(section => {
    if (!section.form?.resource) {
      return false;
    }

    const formSection = note.sections.flatMap(n => n.format === "Form" && n.name === section.name ? n : []).at(0);

    return section.form.resource.elements.some(element => {
      if (element.type !== "Signature" || !element.required) {
        return false;
      }

      const answer = formSection?.formAnswers.find(a => a.linkId === element.linkId);
      return !answer?.values.some(answerValueHasValue);
    });
  });

  if (missingSignatureElements) {
    return false;
  }

  return true;
}

function isNoteRouted(note: Note): boolean {
  return note.reviewers.length > 0 || !!note.extensions?.some(e => e.name === "reviewers-assigned" && e.value === "true");
}
