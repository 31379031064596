import { createContext, useContext, useEffect, useMemo } from "react";
import { Intent } from "@blueprintjs/core";
import { HexColor } from "../utils";
import { useUnmountEffect } from "../hooks";

const lightScheme = {
  dark: false,
  font: {
    active: "var(--pt-intent-primary)",
    default: "var(--pt-text-color)",
    muted: "var(--pt-text-color-muted)",
    disabled: "var(--pt-text-color-disabled)",
    heading: "var(--pt-heading-color)",
    label: "var(--pt-text-color-label)",
    invalid: "var(--text-color-invalid)",
    intents: {
      none: "var(--pt-text-color)",
      primary: "var(--themecolor2)",
      success: "var(--green2)",
      warning: "var(--orange1)",
      danger: "var(--red2)",
    },
  },
  background: {
    default: "var(--background-default)",
    muted: "var(--background-muted)",
    aside: "var(--background-aside)",
    asideMain: "var(--background-aside-main)",
    card: "var(--card-background-color)",
    cardAlt: "var(--card-alt-background-color)",
    navbar: "var(--navbar-background-color)",
    intents: {
      none: "transparent",
      primary: "rgba(var(--themecolor3-rgb), 0.1)",
      success: "rgba(var(--pt-intent-success-rgb), 0.1)",
      warning: "rgba(var(--pt-intent-warning-rgb), 0.1)",
      danger: "rgba(var(--pt-intent-danger-rgb), 0.1)",
    },
  },
  border: {
    default: "var(--border-color)",
    muted: "var(--border-muted)",
    intents: {
      none: "transparent",
      primary: "rgba(var(--themecolor3-rgb), 0.5)",
      success: "rgba(var(--pt-intent-success-rgb), 0.5)",
      warning: "rgba(var(--pt-intent-warning-rgb), 0.5)",
      danger: "rgba(var(--pt-intent-danger-rgb), 0.5)",
    },
  },
  table: {
    hover: "rgba(var(--gray5-rgb), 0.3)",
    border: "inset 0 -1px 0 0 rgba(var(--gray4-rgb), 0.4), inset 0 1px 0 0 rgba(var(--gray4-rgb), 0.4)",
    header: "var(--light)",
  },
  input: {
    background: {
      default: "var(--input-background-color)",
      disabled: "var(--input-background-color-disabled)",
      primary: "rgba(var(--themecolor3-rgb), 0.1)",
      success: "rgba(var(--pt-intent-success-rgb), 0.1)",
      warning: "rgba(var(--pt-intent-warning-rgb), 0.1)",
      danger: "rgba(var(--pt-intent-danger-rgb), 0.1)",
    },
    border: {
      default: "var(--pt-input-box-shadow)",
      focus: "var(--input-box-shadow-focused)",
      disabled: "none",
      primary: "var(--input-box-shadow-primary)",
      success: "var(--input-box-shadow-success)",
      warning: "var(--input-box-shadow-warning)",
      danger: "var(--input-box-shadow-danger)",
    },
  },
  button: {
    default: "var(--button-background-color)",
    hover: "var(--button-background-color-hover)",
    active: "var(--button-background-color-active)",
    minimal: {
      hover: "var(--minimal-button-background-color-hover)",
      active: "var(--minimal-button-background-color-active)",
    },
  },
  elevations: {
    zero: "var(--pt-elevation-shadow-0)",
    one: "var(--pt-elevation-shadow-1)",
    two: "var(--pt-elevation-shadow-2)",
    three: "var(--pt-elevation-shadow-3)",
    four: "var(--pt-elevation-shadow-4)",
    wide: "var(--pt-elevation-shadow-wide)",
    0: "var(--pt-elevation-shadow-0)",
    1: "var(--pt-elevation-shadow-1)",
    2: "var(--pt-elevation-shadow-2)",
    3: "var(--pt-elevation-shadow-3)",
    4: "var(--pt-elevation-shadow-4)",
  },
  modal: {
    default: "#f9f9f9" as HexColor,
  },
  scrollbar: {
    default: "var(--gray4)",
  },
  placeholder: {
    default: "var(--input-placeholder-color)",
  },
  icon: {
    default: "var(--gray2)",
    muted: "var(--gray4)",
  },
};

export type ColorScheme = typeof lightScheme;

const darkScheme: ColorScheme = {
  dark: true,
  font: {
    active: "var(--white)",
    default: "var(--pt-dark-text-color)",
    muted: "var(--pt-dark-text-color-muted)",
    disabled: "var(--pt-dark-text-color-disabled)",
    heading: "var(--light-gray5)",
    label: "var(--pt-dark-text-color-label)",
    invalid: "var(--dark-text-color-invalid)",
    intents: {
      none: "var(--pt-dark-text-color)",
      primary: "var(--themecolor4)",
      success: "var(--green4)",
      warning: "var(--orange4)",
      danger: "var(--red4)",
    },
  },
  background: {
    default: "var(--dark-background-default)",
    muted: "var(--dark-background-muted)",
    aside: "var(--dark-background-aside)",
    asideMain: "var(--dark-background-aside-main)",
    card: "var(--dark-card-background-color)",
    cardAlt: "var(--dark-card-alt-background-color)",
    navbar: "var(--dark-navbar-background-color)",
    intents: {
      none: "transparent",
      primary: "rgba(var(--themecolor4-rgb), 0.1)",
      success: "rgba(var(--green4-rgb), 0.1)",
      warning: "rgba(var(--orange4-rgb), 0.1)",
      danger: "rgba(var(--red4-rgb), 0.1)",
    },
  },
  border: {
    default: "var(--dark-border-color)",
    muted: "var(--dark-border-color)",
    intents: {
      none: "transparent",
      primary: "rgba(var(--themecolor4-rgb), 0.5)",
      success: "rgba(var(--green4-rgb), 0.5)",
      warning: "rgba(var(--orange4-rgb), 0.5)",
      danger: "rgba(var(--red4-rgb), 0.5)",
    },
  },
  table: {
    hover: "rgba(var(--gray1-rgb), 0.3)",
    border: "inset 0 -1px 0 0 var(--dark-border-color)",
    header: "var(--dark-gray3)",
  },
  input: {
    background: {
      default: "var(--dark-input-background-color)",
      disabled: "var(--dark-input-background-color-disabled)",
      primary: "rgba(var(--themecolor4-rgb), 0.1)",
      success: "rgba(var(--green4-rgb), 0.1)",
      warning: "rgba(var(--orange4-rgb), 0.1)",
      danger: "rgba(var(--red4-rgb), 0.1)",
    },
    border: {
      default: "var(--pt-dark-input-box-shadow)",
      focus: "var(--dark-input-box-shadow-focused)",
      disabled: "none",
      primary: "var(--dark-input-box-shadow-primary)",
      success: "var(--dark-input-box-shadow-success)",
      warning: "var(--dark-input-box-shadow-warning)",
      danger: "var(--dark-input-box-shadow-danger)",
    },
  },
  button: {
    default: "var(--dark-button-background-color)",
    hover: "var(--dark-button-background-color-hover)",
    active: "var(--dark-button-background-color-active)",
    minimal: {
      hover: "var(--dark-minimal-button-background-color-hover)",
      active: "var(--dark-minimal-button-background-color-active)",
    },
  },
  elevations: {
    zero: "var(--pt-dark-elevation-shadow-0)",
    one: "var(--pt-dark-elevation-shadow-1)",
    two: "var(--pt-dark-elevation-shadow-2)",
    three: "var(--pt-dark-elevation-shadow-3)",
    four: "var(--pt-dark-elevation-shadow-4)",
    wide: "var(--pt-dark-elevation-shadow-wide)",
    0: "var(--pt-dark-elevation-shadow-0)",
    1: "var(--pt-dark-elevation-shadow-1)",
    2: "var(--pt-dark-elevation-shadow-2)",
    3: "var(--pt-dark-elevation-shadow-3)",
    4: "var(--pt-dark-elevation-shadow-4)",
  },
  modal: {
    default: "#37393f",
  },
  scrollbar: {
    default: "var(--dark-gray2)",
  },
  placeholder: {
    default: "var(--dark-input-placeholder-color)",
  },
  icon: {
    default: "var(--light-gray5)",
    muted: "var(--gray5)",
  },
};

export type FontAppearance = "Normal" | "Large";
export type ColorSchemeName = "light" | "dark";

export interface ColorSchemeContext {
  readonly colorScheme: ColorScheme;
  readonly fontAppearance: FontAppearance;
  readonly zoom: number;
  readonly gridSize: number;
  readonly overridden: boolean;
  setColorScheme(colorSchemeName: ColorSchemeName): void;
  setFontAppearance(fontAppearance: FontAppearance): void;
  setOverridden(overridden: boolean): void;
}

export const colorSchemes = {
  light: lightScheme,
  dark: darkScheme,
};

let currentColorScheme: ColorSchemeName = "light";
let currentFontAppearance: FontAppearance = "Normal";
let currentOverridden = false;
export const ColorSchemeContext = createContext<ColorSchemeContext>({
  get colorScheme(): ColorScheme {
    return currentColorScheme === "dark" ? colorSchemes.dark : colorSchemes.light;
  },
  get fontAppearance(): FontAppearance {
    return currentFontAppearance;
  },
  get zoom(): number {
    return currentFontAppearance === "Large" ? 1.25 : 1;
  },
  get gridSize(): number {
    return 10;
  },
  get overridden(): boolean {
    return currentOverridden;
  },
  setColorScheme(colorScheme: ColorSchemeName) {
    currentColorScheme = colorScheme;
  },
  setFontAppearance(fontAppearance: FontAppearance) {
    currentFontAppearance = fontAppearance;
  },
  setOverridden(overridden: boolean): void {
    currentOverridden = overridden;
  },
});

export const useColorScheme = () => useContext(ColorSchemeContext);

export function intentColor(intent: Intent) {
  switch (intent) {
    default:
    case "none": return "var(--pt-intent-none)";
    case "primary": return "var(--pt-intent-primary)";
    case "success": return "var(--pt-intent-success)";
    case "warning": return "var(--pt-intent-warning)";
    case "danger": return "var(--pt-intent-danger)";
  }
}

export function intentRgb(intent: Intent) {
  switch (intent) {
    default:
    case "none": return "var(--pt-intent-none)";
    case "primary": return "var(--pt-intent-primary-rgb)";
    case "success": return "var(--pt-intent-success-rgb)";
    case "warning": return "var(--pt-intent-warning-rgb)";
    case "danger": return "var(--pt-intent-danger-rgb)";
  }
}

export function intentTextColor(intent: Intent) {
  switch (intent) {
    default:
    case "none": return "var(--pt-text-color)";
    case "primary": return "var(--themecolor2)";
    case "success": return "var(--green1)";
    case "warning": return "var(--orange1)";
    case "danger": return "var(--red2)";
  }
}

export interface Theme extends ColorScheme {
  gridSize: number;
  sizes: {
    font: number;
    fontLarge: number;
    fontSmall: number;
  };
}

export function getTheme(scheme: ColorSchemeName, gridSize: number): Theme {
  return {
    ...scheme === "dark" ? colorSchemes.dark : colorSchemes.light,
    gridSize,
    sizes: {
      font: gridSize * 1.4,
      fontLarge: gridSize * 1.6,
      fontSmall: gridSize * 1.2,
    },
  };
}

export interface ColorSchemeOverrideOptions {
  colorScheme?: ColorSchemeName;
  fontAppearance?: FontAppearance;
}

export function useColorSchemeOverride(options: ColorSchemeOverrideOptions) {
  const { fontAppearance, colorScheme, setFontAppearance, setColorScheme, setOverridden } = useColorScheme();

  const defaultTheme = useMemo(() => ({ dark: colorScheme.dark, fontAppearance }), []);

  useEffect(() => {
    overrideTheme();
  }, []);

  useUnmountEffect(() => {
    restoreTheme();
  });

  function restoreTheme() {
    setColorScheme(defaultTheme.dark ? "dark" : "light");
    setFontAppearance(defaultTheme.fontAppearance);
    setOverridden(false);
  }

  function overrideTheme() {
    if (options.colorScheme) {
      setColorScheme(options.colorScheme);
    }

    if (options.fontAppearance) {
      setFontAppearance(options.fontAppearance);
    }

    setOverridden(true);
  }
}
