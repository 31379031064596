import { NoteSectionForm } from "@remhealth/apollo";
import { type Labeling, Yup } from "@remhealth/core";
import { FormNoteSection, ValidationMode } from "~/notes/types";
import { QuestionnaireContext } from "~/questionnaire/contexts";
import { questionnaireAnswerSchemaFactory } from "~/questionnaire/questionnaireSchema";

export interface FormNoteSectionSchemaContext {
  forms: NoteSectionForm[];
  questionnaireContext: QuestionnaireContext;
}

export function formNoteSectionSchema(context: FormNoteSectionSchemaContext, validationMode: ValidationMode, labels: Labeling) {
  const questionnaireContext = context.questionnaireContext;
  const factory = questionnaireAnswerSchemaFactory(context.forms, { labels, validationMode, ...questionnaireContext });

  return Yup.lazy<FormNoteSection>(inferFormNoteSectionSchema);

  function inferFormNoteSectionSchema(value: FormNoteSection) {
    const questionnaireAnswersSchema = factory(value.noteSectionForm);

    return Yup.object<FormNoteSection>({
      name: Yup.string().required(),
      noteSectionForm: Yup.mixed(),
      required: Yup.boolean(),
      questionnaireAnswers: questionnaireAnswersSchema,
    });
  }
}
