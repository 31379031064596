import { Intent } from "@blueprintjs/core";
import { isEqual, pick } from "lodash-es";

type FormErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[] ? Values[K][number] extends object ? FormErrors<Values[K][number]>[] | string | string[] : string | string[] : Values[K] extends object ? FormErrors<Values[K]> : string;
};

type FormTouched<Values> = {
  [K in keyof Values]?: Values[K] extends any[] ? Values[K][number] extends object ? FormTouched<Values[K][number]>[] : boolean : Values[K] extends object ? FormTouched<Values[K]> : boolean;
};

export interface FormFieldsProps<Values> extends FormFieldsState<Values>, FormFieldsActions {
}

export interface FormFieldsState<Values> {
  readonly values: Values;
  readonly initialValues: Values;
  readonly errors: FormErrors<Values>;
  readonly touched: FormTouched<Values>;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
}

export interface FormFieldsActions {
  setFieldValue(field: string, value: any): void;
  setFieldValue(field: string, updateFn: (prevValue: any) => any): void;
  setFieldTouched(field: string, touched?: boolean): void;
  setFieldError(field: string, message: string | undefined): void;
}

export interface FieldValidation {
  readonly name: string | undefined;
  /** Returns true if the field (and any nested path of this field) is valid. */
  readonly valid: boolean;
  /** Returns true if the field should show an error state.  This typically means the field has been both touched and has an error. */
  readonly error: boolean;
  readonly disabled?: boolean;
  readonly errorText: string | undefined;
}

export interface FormField<Value> extends FieldValidation {
  readonly name: string;
  readonly value: Value;
  readonly initialValue: Value;
  readonly touched: boolean;
  readonly fields: FormFields<Value>;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  item<TIn>(this: FormField<Iterable<TIn> | undefined>, index: number): FormFields<TIn> | undefined;
  onChange(value: Value): void;
  onChange(updateFn: (prevValue: Value) => Value): void;
  onTouched(): void;
  setTouched(touched: boolean): void;
  setError(message: string | undefined): void;
}

export type FormFields<Values> = {
  [K in keyof Values]: FormField<Values[K]>;
};

class Field implements FormField<any> {
  private readonly form: FormFieldsProps<any>;
  private readonly path: (string | number | symbol)[];

  constructor(form: FormFieldsProps<any>, path: (string | number | symbol)[]) {
    this.form = form;
    this.path = path;
  }

  public get name(): string {
    return this.path.join(".");
  }

  public get value(): any {
    return getIn(this.form.values, this.path);
  }

  public get initialValue(): any {
    return getIn(this.form.initialValues, this.path);
  }

  public get valid(): boolean {
    const error = getIn(this.form.errors, this.path);
    // Is invalid if there is an error at or within the path
    return !error;
  }

  public get error(): boolean {
    if (!this.touched) {
      return false;
    }

    const error = getIn(this.form.errors, this.path);
    return typeof error === "string";
  }

  public get touched(): boolean {
    const touched = getIn(this.form.touched, this.path);
    return touched !== undefined && touched !== false;
  }

  public get readOnly(): boolean | undefined {
    return this.form.readOnly;
  }

  public get disabled(): boolean | undefined {
    return this.form.disabled;
  }

  public get errorText(): string | undefined {
    if (!this.touched) {
      return undefined;
    }

    const error = getIn(this.form.errors, this.path);
    if (error && typeof error === "string") {
      return error;
    }

    return undefined;
  }

  public get fields(): FormFields<any> {
    return createFormFields<any>(this.form, this.path);
  }

  public item<TIn>(this: Field, index: number): FormFields<TIn> | undefined {
    return createFormInternal<TIn>(this.form, index, this.path);
  }

  public onChange = (value: any): void => {
    this.form.setFieldValue(this.name, value);
  };

  public onTouched = (): void => {
    this.setTouched(true);
  };

  public setTouched = (touched: boolean): void => {
    this.form.setFieldTouched(this.name, touched);
  };

  public setError = (message: string | undefined): void => {
    this.form.setFieldError(this.name, message);
  };
}

export function createFormFields<Values>(form: FormFieldsProps<Values>, prefixes: (string | number | symbol)[]): FormFields<Values> {
  return createFormInternal(form, undefined, prefixes);
}

function createFormInternal<Values>(form: FormFieldsProps<Values>, index: undefined, prefixes: (string | number | symbol)[]): FormFields<Values>;
function createFormInternal<Values>(form: FormFieldsProps<Values>, index: number, prefixes: (string | number | symbol)[]): FormFields<Values> | undefined;
function createFormInternal<Values>(form: FormFieldsProps<Values>, index: number | undefined, prefixes: (string | number | symbol)[]): FormFields<Values> | undefined {
  let values = getIn(form.values, prefixes);

  if (Array.isArray(values)) {
    if (index === undefined) {
      return values.map((_, index) => new Field(form, [...prefixes, index])) as any;
    }

    if (index >= values.length) {
      return undefined;
    }

    prefixes = [...prefixes, index];
    values = values[index];
  }

  const validator: {
    [K in keyof Values]?: FormField<Values[K]>;
  } = {};

  for (const key in values) {
    validator[key as keyof Values] = new Field(form, [...prefixes, key]);
  }

  return validator as FormFields<Values>;
}

export function areFormFieldsEqual<T>(left: FormField<T> | undefined, right: FormField<T> | undefined): boolean {
  // Special check for field props
  const fieldProps: (keyof FormField<T>)[] = ["name", "disabled", "readOnly", "errorText", "error", "touched", "valid", "value", "initialValue"];
  return isEqual(pick(left, fieldProps), pick(right, fieldProps));
}

export function getIn(obj: any, paths: (string | number | symbol)[]): any {
  for (let i = 0; i < paths.length; ++i) {
    if (!obj) {
      break;
    }

    obj = obj[paths[i]];
  }
  return obj;
}

export function getIntent(validation?: FieldValidation): Intent | undefined {
  return validation?.error ? "danger" : undefined;
}

export interface FormError {
  name: string;
  error: string;
}

export function flattenFormErrors(errors: FormErrors<any>): FormError[] {
  const errorList: FormError[] = [];
  for (const name in errors) {
    const error = errors[name];
    if (error) {
      if (typeof error === "string") {
        errorList.push({ name, error });
      } else if (Array.isArray(error)) {
        for (const childIndex in error) {
          const childError = error[childIndex];
          if (typeof childError === "string") {
            errorList.push({ name: `${name}.${childIndex}`, error: childError });
          } else {
            const childErrors = flattenFormErrors(childError);
            if (childErrors.length > 0) {
              errorList.push(...childErrors.map(e => ({ name: `${name}.${childIndex}.${e.name}`, error: e.error })));
            }
          }
        }
      } else if (typeof error === "object") {
        const childErrors = flattenFormErrors(error);
        if (childErrors.length > 0) {
          errorList.push(...childErrors.map(e => ({ name: `${name}.${e.name}`, error: e.error })));
        }
      }
    }
  }
  return errorList;
}
